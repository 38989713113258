.category-header {
  h1,
  h2 {
    margin: 0;

    color: $brand-primary;
  }

  h1 {
    font: normal 1.75rem/1 $font-family-base;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.4rem;
    }
  }

  h2 {
    margin-bottom: $spacer;

    font: normal 1rem/1 $font-family-base;


    &:before {
      @include sprite($icon_default);

      content: '';

      display: inline-block;
      margin-right: $spacer;

      vertical-align: middle;
    }
  }

  hgroup {
    padding: $spacer;

    background: #fafbfb url('https://static.medi-king.de/images/header/header_default.png') right top no-repeat;
    border: 1px solid $gray-200;

    @each $category, $offset-y in $categories {
      &.#{$category} {
        // add header background according to category (category list in global.scss)
        background-image: url('https://static.medi-king.de/images/header/header_' + $category + '.png');

        // add icons to header headline according to category (category list in global.scss)
        h2:before {
          background-position: 0 $offset-y;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      background-image: none!important;
    }
  }

  hgroup.aktionen {
    background-image: url('https://static.medi-king.de/images/header/header_sonderangebot.png');
  }

  hgroup.sale {
    background-image: url('https://static.medi-king.de/images/header/header_sale.png');
  }
  hgroup.new {
    background-image: url('https://static.medi-king.de/images/header/header_new.png');
  }
}

.menu-collapsible .menu-category {
// .menu-category {
  border-bottom: 1px solid $gray-200;
  line-height: 1;

  & > li > .menu-link {
    display: block;
    padding: .75rem 2rem;
    padding-left: 2.5rem;

    background-image: linear-gradient(to bottom, lighten($gray-100, 5%), $gray-200);

    // add through JS when accordeon functionality is active
    // &:hover {
    //   cursor: pointer;
    //   font-weight: bold;
    //   text-decoration: underline;
    // }
  }

  .active {
    position: relative;

    font-weight: bold;
  }

  .mainCategory > .active {
    &:before {
      @include sprite($arrow4);

      content: '';

      display: block;
      position: absolute;
      left: 1.5rem;
      top: 50%;
      margin-top: ($arrow4-height / -2);
    }
  }

  .menu-item {
    display: block;
  }

  .menu-link {
    display: block;
    padding: 1rem;

    // border-bottom: 1px solid $gray-200;
    color: $body-color;

    &:hover {
      background-color: $gray-100;
      text-decoration: none;
    }
  }

  .menu-link {
    border: 1px solid $gray-200;
    border-bottom-width: 0;
  }

  .menu-sublist {
    .menu-link {
      padding-left: 2.5rem;
    }
  }

  .menu-sublist .menu-sublist {
    .menu-link {
      padding-left: 4.5rem;

      border-top: 0;
    }
  }
}

// Display of productListItem in category
.productList .productListItem {
  @include make-col(6);
  // width: 49%;
  // width: calc(50% - .5rem);

  @include media-breakpoint-up(md) {
    @include make-col(4);
    // width: 32%;
    // width: calc(33% - .667rem);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(3);
    // width: 24%;
    // width: calc(25% - .75rem);
  }
}

.menu-multilevel {
  max-width: 25rem;
  position: relative;
  padding-top: $spacer;

  .mml-trigger {
    position: relative;
    top: ($spacer * -.5);
  }

  .mml-menu {
    width: 100%;

    box-shadow: $dropdown-box-shadow;
  }

  .mml-item {
    background-color: $brand-secondary;

    .menu-link {
      display: block;
      padding: ($spacer) ($spacer * 5/3);

      color: #fff;

      &:hover {
        background-color: rgba(#fff, .1);
      }
    }
  }
}
