.pdf-grid {
  @include make-row();
  // justify-content: space-between;
  margin: 0 ($grid-gutter-width / -4);
  padding: 0;
}

.pdf-item {
  @include make-col-ready();
  padding-left: ($grid-gutter-width / 4);
  padding-right: ($grid-gutter-width / 4);

  margin-bottom: 1rem !important;
}

.pdf-item_inner {
  background-color: $gray-100;
  border: 1px solid $gray-300;

  &:hover,
  &:focus {
    border-color: $gray-400;
    box-shadow: 0 0 5px rgba(#000, .1);
    text-decoration: none;
  }
}

.pdf-item_imgWrapper {
  padding: ($spacer * .5);
  display: flex;
  align-items: center;

  background-color: #fff;

  img {
    margin: auto;
  }
}

.pdf-item_details {
  padding: .5rem;

  color: $body-color;
  font-size: .92rem;
}

.pdf-item_title {
  height: 3.24rem;

  font-size: .92rem;
  line-height: 1.2;
  overflow: hidden;
  margin-bottom: .5rem;
  text-overflow: ellipsis;
}

.pdf-item_description,
.pdf-item_link {
  display: none;
}
