.checkout-headline {
  position: absolute;
  top: 1rem;
  right: 0;
  z-index: ($zindex-navbar + 1);

  color: #8fbf00;
  font: 15px/100% Arial;
  font-weight: normal;

  @include media-breakpoint-up(lg) {
    top: -2rem;
  }
}

@include media-breakpoint-down(sm) {
  .checkout-headline-ext {
    @include sr-only;
  }
}

.checkout-steps {
  margin: -1px ($grid-gutter-width * -1) ($spacer * 2);
  padding: 0 ($grid-gutter-width * .5);

  background-color: $gray-100;
  border-top: 1px solid $gray-200;
  border-bottom: 1px solid #fff;
  box-shadow: 0 2px 6px rgba(#000, .08);
  list-style: none;

  .step {
    padding: 1rem;

    counter-increment: checkout-steps;
    font: 1.25rem/100% Arial;

    &.current {
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    .step:not(.current) {
      @include sr-only;
    }
  }

  span {
    position: relative;

    &:before {
      content: counter(checkout-steps);

      margin-right: $spacer;
      padding: .4rem .7rem;

      background-color: $brand-secondary;
      border-radius: 50%;
      box-shadow: 1px 1px 0 #fff, -1px -1px 0 rgba(#000, .1);
      color: #fff;
      font: 1rem/100% Arial;
      font-weight: bold;
    }
  }

  .current {
    font-weight: bold;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    margin: 0 ($grid-gutter-width * -1.2) ($spacer * 2);
    padding: 0;

    .step {
      // flex-basis: 20%;
      flex-grow: 1;
      padding-left: 5rem;
      position: relative;

      &:before,
      &:after {
        content: '';

        position: absolute;
        height: 0;
        width: 0;
        top: -1px;
        right: -21px;
        z-index: 10;

        border-color: transparent transparent transparent #fff;
        border-style: solid;
        border-width: 20px 0 20px 20px;
      }

      &:after {
        top: 0;
        right: -19px;
        z-index: 11;

        border-width: 19.5px 0 19.5px 19px;
        border-color: transparent transparent transparent $gray-100;
      }

      &:last-child {
        &:before,
        &:after {
          display: none;
        }
      }
    }

    span {
      &:before {
        position: absolute;
        top: -3px;
        left: -32px;

        background-color: $gray-400;
      }
    }

    .current {
      background-color: #97c900;
      color: #fff;
      text-shadow: -1px -1px 0 rgba(#000, .1);

      &:after {
        border-color: transparent transparent transparent #97c900;
      }

      span:before {
        box-shadow: none;
        background-color: rgba(#000, .1);
      }
    }
  }
}

.checkout-options {
  display: flex;
  justify-content: space-between;
}

// #payment
.paymentMethod-index {
  .meta-box {
    margin-top: $spacer;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
}

// #review
.checkout-form-wrapper,
.sidebar-checkout {
  padding: $spacer;

  background-color: $gray-100;
  border: 1px solid $gray-200;
}

.sidebar-checkout {
  margin-bottom: $spacer;
}

.sidebar-checkout-box {
  margin-bottom: $spacer;
  padding-bottom: $spacer;

  border-bottom: 1px solid $gray-300;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;

    border-bottom: 0;
  }
}

@include media-breakpoint-up(md) {
  .sidebar-checkout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .sidebar-checkout-box {
    flex-shrink: 0;
    width: 30%;
    width: calc(33% - 1rem);

    border-width: 0;
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-checkout {
    display: block;
  }

  .sidebar-checkout-box {
    width: auto;
    border-width: 1px;
  }
}

