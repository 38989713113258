/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #285492;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #285492;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #183156;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #ced4da;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #ced4da; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 98%; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 98%; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #285492;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F3F7FA; }

.table-hover tbody tr:hover {
  color: #285492;
  background-color: #e9ecef; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c3cfe0; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8fa6c6; }

.table-hover .table-primary:hover {
  background-color: #b2c2d7; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b2c2d7; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #daeeca; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #badf9d; }

.table-hover .table-secondary:hover {
  background-color: #cde8b7; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cde8b7; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d1ebd1; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #aadaaa; }

.table-hover .table-success:hover {
  background-color: #bfe3bf; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bfe3bf; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d1edf6; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #aadeee; }

.table-hover .table-info:hover {
  background-color: #bce5f2; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #bce5f2; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbe8cd; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f7d4a3; }

.table-hover .table-warning:hover {
  background-color: #f9ddb5; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f9ddb5; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4cfce; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eba6a3; }

.table-hover .table-danger:hover {
  background-color: #efbbb9; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #efbbb9; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9fafb; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f4f5f7; }

.table-hover .table-light:hover {
  background-color: #eaedf1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eaedf1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #e9ecef; }

.table-hover .table-active:hover {
  background-color: #dadfe4; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #dadfe4; }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group textarea,
.form-group select {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.15rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group input[type="password"],
    .form-group textarea,
    .form-group select {
      transition: none; } }
  .form-control::-ms-expand, .form-group input[type="text"]::-ms-expand,
  .form-group input[type="email"]::-ms-expand,
  .form-group input[type="password"]::-ms-expand,
  .form-group textarea::-ms-expand,
  .form-group select::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .form-group input:focus[type="text"],
  .form-group input:focus[type="email"],
  .form-group input:focus[type="password"],
  .form-group textarea:focus,
  .form-group select:focus {
    color: #495057;
    background-color: #fff;
    border-color: #6593d5;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(40, 84, 146, 0.25); }
  .form-control::placeholder, .form-group input[type="text"]::placeholder,
  .form-group input[type="email"]::placeholder,
  .form-group input[type="password"]::placeholder,
  .form-group textarea::placeholder,
  .form-group select::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-group input:disabled[type="text"],
  .form-group input:disabled[type="email"],
  .form-group input:disabled[type="password"],
  .form-group textarea:disabled,
  .form-group select:disabled, .form-control[readonly], .form-group input[readonly][type="text"],
  .form-group input[readonly][type="email"],
  .form-group input[readonly][type="password"],
  .form-group textarea[readonly],
  .form-group select[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control, .form-group input[type="date"][type="text"],
.form-group input[type="date"][type="email"],
.form-group input[type="date"][type="password"],
input[type="time"].form-control,
.form-group input[type="time"][type="text"],
.form-group input[type="time"][type="email"],
.form-group input[type="time"][type="password"],
input[type="datetime-local"].form-control,
.form-group input[type="datetime-local"][type="text"],
.form-group input[type="datetime-local"][type="email"],
.form-group input[type="datetime-local"][type="password"],
input[type="month"].form-control,
.form-group input[type="month"][type="text"],
.form-group input[type="month"][type="email"],
.form-group input[type="month"][type="password"] {
  appearance: none; }

select.form-control:-moz-focusring,
.form-group select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value,
.form-group select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25; }

.col-form-label-lg {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.25;
  color: #285492;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1rem; }

.form-control-lg {
  height: calc(3.875rem + 2px);
  padding: 1rem 1.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

select.form-control[size],
.form-group select[size], select.form-control[multiple],
.form-group select[multiple] {
  height: auto; }

textarea.form-control,
.form-group textarea {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #ced4da; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #5cb85c; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 3px 8px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(92, 184, 92, 0.9);
  border-radius: 0.15rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .was-validated .form-group input:valid[type="text"], .form-group .was-validated input:valid[type="text"],
.was-validated .form-group input:valid[type="email"], .form-group .was-validated input:valid[type="email"],
.was-validated .form-group input:valid[type="password"], .form-group .was-validated input:valid[type="password"],
.was-validated .form-group textarea:valid, .form-group .was-validated textarea:valid,
.was-validated .form-group select:valid, .form-group .was-validated select:valid, .form-control.is-valid, .form-group input.is-valid[type="text"],
.form-group input.is-valid[type="email"],
.form-group input.is-valid[type="password"],
.form-group textarea.is-valid,
.form-group select.is-valid {
  border-color: #5cb85c;
  padding-right: 2.25rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3125em + 0.25rem) center;
  background-size: calc(0.625em + 0.5rem) calc(0.625em + 0.5rem); }
  .was-validated .form-control:valid:focus, .was-validated .form-group input:valid:focus[type="text"], .form-group .was-validated input:valid:focus[type="text"],
  .was-validated .form-group input:valid:focus[type="email"], .form-group .was-validated input:valid:focus[type="email"],
  .was-validated .form-group input:valid:focus[type="password"], .form-group .was-validated input:valid:focus[type="password"],
  .was-validated .form-group textarea:valid:focus, .form-group .was-validated textarea:valid:focus,
  .was-validated .form-group select:valid:focus, .form-group .was-validated select:valid:focus, .form-control.is-valid:focus, .form-group input.is-valid:focus[type="text"],
  .form-group input.is-valid:focus[type="email"],
  .form-group input.is-valid:focus[type="password"],
  .form-group textarea.is-valid:focus,
  .form-group select.is-valid:focus {
    border-color: #5cb85c;
    box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25); }

.was-validated select.form-control:valid,
.was-validated .form-group select:valid, .form-group .was-validated select:valid, select.form-control.is-valid,
.form-group select.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid,
.was-validated .form-group textarea:valid, .form-group .was-validated textarea:valid, textarea.form-control.is-valid,
.form-group textarea.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(0.3125em + 0.25rem) right calc(0.3125em + 0.25rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #5cb85c;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.625em + 0.5rem) calc(0.625em + 0.5rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #5cb85c;
    box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #5cb85c; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #5cb85c; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #5cb85c; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #80c780;
  background-color: #80c780; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #5cb85c; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #5cb85c; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #5cb85c;
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d9534f; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 3px 8px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(217, 83, 79, 0.9);
  border-radius: 0.15rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .was-validated .form-group input:invalid[type="text"], .form-group .was-validated input:invalid[type="text"],
.was-validated .form-group input:invalid[type="email"], .form-group .was-validated input:invalid[type="email"],
.was-validated .form-group input:invalid[type="password"], .form-group .was-validated input:invalid[type="password"],
.was-validated .form-group textarea:invalid, .form-group .was-validated textarea:invalid,
.was-validated .form-group select:invalid, .form-group .was-validated select:invalid, .form-control.is-invalid, .form-group input.is-invalid[type="text"],
.form-group input.is-invalid[type="email"],
.form-group input.is-invalid[type="password"],
.form-group textarea.is-invalid,
.form-group select.is-invalid {
  border-color: #d9534f;
  padding-right: 2.25rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3125em + 0.25rem) center;
  background-size: calc(0.625em + 0.5rem) calc(0.625em + 0.5rem); }
  .was-validated .form-control:invalid:focus, .was-validated .form-group input:invalid:focus[type="text"], .form-group .was-validated input:invalid:focus[type="text"],
  .was-validated .form-group input:invalid:focus[type="email"], .form-group .was-validated input:invalid:focus[type="email"],
  .was-validated .form-group input:invalid:focus[type="password"], .form-group .was-validated input:invalid:focus[type="password"],
  .was-validated .form-group textarea:invalid:focus, .form-group .was-validated textarea:invalid:focus,
  .was-validated .form-group select:invalid:focus, .form-group .was-validated select:invalid:focus, .form-control.is-invalid:focus, .form-group input.is-invalid:focus[type="text"],
  .form-group input.is-invalid:focus[type="email"],
  .form-group input.is-invalid:focus[type="password"],
  .form-group textarea.is-invalid:focus,
  .form-group select.is-invalid:focus {
    border-color: #d9534f;
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.was-validated select.form-control:invalid,
.was-validated .form-group select:invalid, .form-group .was-validated select:invalid, select.form-control.is-invalid,
.form-group select.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid,
.was-validated .form-group textarea:invalid, .form-group .was-validated textarea:invalid, textarea.form-control.is-invalid,
.form-group textarea.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(0.3125em + 0.25rem) right calc(0.3125em + 0.25rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d9534f;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.625em + 0.5rem) calc(0.625em + 0.5rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #d9534f;
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d9534f; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9534f; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #d9534f; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e27c79;
  background-color: #e27c79; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d9534f; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9534f; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d9534f;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .form-group input[type="text"], .form-group .form-inline input[type="text"],
    .form-inline .form-group input[type="email"], .form-group .form-inline input[type="email"],
    .form-inline .form-group input[type="password"], .form-group .form-inline input[type="password"],
    .form-inline .form-group textarea, .form-group .form-inline textarea,
    .form-inline .form-group select, .form-group .form-inline select {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.btn {
  display: inline-block;
  font-weight: bold;
  color: #285492;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.15rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #285492;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(40, 84, 146, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
      .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 3px rgba(40, 84, 146, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #285492;
  border-color: #285492;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background-color: #204374;
    border-color: #1d3d6a; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #204374;
    border-color: #1d3d6a;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(72, 110, 162, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #285492;
    border-color: #285492; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1d3d6a;
    border-color: #1a3760; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 110, 162, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #7ac143;
  border-color: #7ac143;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #fff;
    background-color: #68a737;
    border-color: #629d34; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #68a737;
    border-color: #629d34;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(109, 170, 63, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #7ac143;
    border-color: #7ac143; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #629d34;
    border-color: #5c9431; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(109, 170, 63, 0.5); }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #fff;
    background-color: #48a648;
    border-color: #449d44; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #48a648;
    border-color: #449d44;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(116, 195, 116, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #449d44;
    border-color: #409440; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(116, 195, 116, 0.5); }

.btn-info {
  color: #212529;
  background-color: #5bc0de;
  border-color: #5bc0de;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #fff;
    background-color: #3bb4d8;
    border-color: #31b0d5; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #3bb4d8;
    border-color: #31b0d5;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 169, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #212529;
    background-color: #5bc0de;
    border-color: #5bc0de; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #31b0d5;
    border-color: #2aaacf; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 169, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #212529;
    background-color: #ed9d2b;
    border-color: #ec971f; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #ed9d2b;
    border-color: #ec971f;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(209, 153, 72, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f0ad4e;
    border-color: #f0ad4e; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ec971f;
    border-color: #ea9214; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(209, 153, 72, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background-color: #d23430;
    border-color: #c9302c; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #d23430;
    border-color: #c9302c;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(223, 109, 105, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    border-color: #bf2e29; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(223, 109, 105, 0.5); }

.btn-light {
  color: #212529;
  background-color: #e9ecef;
  border-color: #e9ecef;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #212529;
    background-color: #d3d9df;
    border-color: #cbd3da; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #d3d9df;
    border-color: #cbd3da;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(203, 206, 209, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #cbd3da;
    border-color: #c4ccd4; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(203, 206, 209, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #285492;
  border-color: #285492; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #285492;
    border-color: #285492; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 84, 146, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #285492;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #285492;
    border-color: #285492; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(40, 84, 146, 0.5); }

.btn-outline-secondary {
  color: #7ac143;
  border-color: #7ac143; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #7ac143;
    border-color: #7ac143; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(122, 193, 67, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #7ac143;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #7ac143;
    border-color: #7ac143; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(122, 193, 67, 0.5); }

.btn-outline-success {
  color: #5cb85c;
  border-color: #5cb85c; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #5cb85c;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #5cb85c;
    border-color: #5cb85c; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(92, 184, 92, 0.5); }

.btn-outline-info {
  color: #5bc0de;
  border-color: #5bc0de; }
  .btn-outline-info:hover {
    color: #212529;
    background-color: #5bc0de;
    border-color: #5bc0de; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #5bc0de;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #5bc0de;
    border-color: #5bc0de; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(91, 192, 222, 0.5); }

.btn-outline-warning {
  color: #f0ad4e;
  border-color: #f0ad4e; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f0ad4e;
    border-color: #f0ad4e; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 173, 78, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f0ad4e;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f0ad4e;
    border-color: #f0ad4e; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(240, 173, 78, 0.5); }

.btn-outline-danger {
  color: #d9534f;
  border-color: #d9534f; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d9534f;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d9534f;
    border-color: #d9534f; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(217, 83, 79, 0.5); }

.btn-outline-light {
  color: #e9ecef;
  border-color: #e9ecef; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #e9ecef;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e9ecef;
    border-color: #e9ecef; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: normal;
  color: #285492;
  text-decoration: none; }
  .btn-link:hover {
    color: #183156;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #868e96;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 1.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #285492;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.15rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e5e5e5; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f5f5f5; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #285492; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #ced4da;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #ced4da;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control, .form-group .input-group > input[type="text"],
  .form-group .input-group > input[type="email"],
  .form-group .input-group > input[type="password"],
  .form-group .input-group > textarea,
  .form-group .input-group > select,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control, .form-group .input-group > input[type="text"] + .form-control, .form-group .input-group > input[type="email"] + .form-control, .form-group .input-group > input[type="password"] + .form-control, .form-group .input-group > textarea + .form-control, .form-group .input-group > select + .form-control, .form-group .input-group > .form-control + input[type="text"], .form-group .input-group > input[type="text"] + input[type="text"], .form-group .input-group > input[type="email"] + input[type="text"], .form-group .input-group > input[type="password"] + input[type="text"], .form-group .input-group > textarea + input[type="text"], .form-group .input-group > select + input[type="text"],
    .form-group .input-group > .form-control + input[type="email"],
    .form-group .input-group > input[type="text"] + input[type="email"],
    .form-group .input-group > input[type="email"] + input[type="email"],
    .form-group .input-group > input[type="password"] + input[type="email"],
    .form-group .input-group > textarea + input[type="email"],
    .form-group .input-group > select + input[type="email"],
    .form-group .input-group > .form-control + input[type="password"],
    .form-group .input-group > input[type="text"] + input[type="password"],
    .form-group .input-group > input[type="email"] + input[type="password"],
    .form-group .input-group > input[type="password"] + input[type="password"],
    .form-group .input-group > textarea + input[type="password"],
    .form-group .input-group > select + input[type="password"],
    .form-group .input-group > .form-control + textarea,
    .form-group .input-group > input[type="text"] + textarea,
    .form-group .input-group > input[type="email"] + textarea,
    .form-group .input-group > input[type="password"] + textarea,
    .form-group .input-group > textarea + textarea,
    .form-group .input-group > select + textarea,
    .form-group .input-group > .form-control + select,
    .form-group .input-group > input[type="text"] + select,
    .form-group .input-group > input[type="email"] + select,
    .form-group .input-group > input[type="password"] + select,
    .form-group .input-group > textarea + select,
    .form-group .input-group > select + select,
    .input-group > .form-control + .custom-select,
    .form-group .input-group > input[type="text"] + .custom-select,
    .form-group .input-group > input[type="email"] + .custom-select,
    .form-group .input-group > input[type="password"] + .custom-select,
    .form-group .input-group > textarea + .custom-select,
    .form-group .input-group > select + .custom-select,
    .input-group > .form-control + .custom-file,
    .form-group .input-group > input[type="text"] + .custom-file,
    .form-group .input-group > input[type="email"] + .custom-file,
    .form-group .input-group > input[type="password"] + .custom-file,
    .form-group .input-group > textarea + .custom-file,
    .form-group .input-group > select + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .form-group .input-group > .form-control-plaintext + input[type="text"],
    .form-group .input-group > .form-control-plaintext + input[type="email"],
    .form-group .input-group > .form-control-plaintext + input[type="password"],
    .form-group .input-group > .form-control-plaintext + textarea,
    .form-group .input-group > .form-control-plaintext + select,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .form-group .input-group > .custom-select + input[type="text"],
    .form-group .input-group > .custom-select + input[type="email"],
    .form-group .input-group > .custom-select + input[type="password"],
    .form-group .input-group > .custom-select + textarea,
    .form-group .input-group > .custom-select + select,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .form-group .input-group > .custom-file + input[type="text"],
    .form-group .input-group > .custom-file + input[type="email"],
    .form-group .input-group > .custom-file + input[type="password"],
    .form-group .input-group > .custom-file + textarea,
    .form-group .input-group > .custom-file + select,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus, .form-group .input-group > input:focus[type="text"],
  .form-group .input-group > input:focus[type="email"],
  .form-group .input-group > input:focus[type="password"],
  .form-group .input-group > textarea:focus,
  .form-group .input-group > select:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child), .form-group .input-group > input:not(:first-child)[type="text"],
  .form-group .input-group > input:not(:first-child)[type="email"],
  .form-group .input-group > input:not(:first-child)[type="password"],
  .form-group .input-group > textarea:not(:first-child),
  .form-group .input-group > select:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child), .form-group .input-group:not(.has-validation) > input:not(:last-child)[type="text"],
  .form-group .input-group:not(.has-validation) > input:not(:last-child)[type="email"],
  .form-group .input-group:not(.has-validation) > input:not(:last-child)[type="password"],
  .form-group .input-group:not(.has-validation) > textarea:not(:last-child),
  .form-group .input-group:not(.has-validation) > select:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3), .form-group .input-group.has-validation > input:nth-last-child(n + 3)[type="text"],
  .form-group .input-group.has-validation > input:nth-last-child(n + 3)[type="email"],
  .form-group .input-group.has-validation > input:nth-last-child(n + 3)[type="password"],
  .form-group .input-group.has-validation > textarea:nth-last-child(n + 3),
  .form-group .input-group.has-validation > select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.15rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .form-group .input-group-lg > input:not(textarea)[type="text"],
.form-group .input-group-lg > input:not(textarea)[type="email"],
.form-group .input-group-lg > input:not(textarea)[type="password"],
.form-group .input-group-lg > textarea:not(textarea),
.form-group .input-group-lg > select:not(textarea),
.input-group-lg > .custom-select {
  height: calc(3.875rem + 2px); }

.input-group-lg > .form-control, .form-group .input-group-lg > input[type="text"],
.form-group .input-group-lg > input[type="email"],
.form-group .input-group-lg > input[type="password"],
.form-group .input-group-lg > textarea,
.form-group .input-group-lg > select,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1rem 1.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem; }

.input-group-sm > .form-control:not(textarea), .form-group .input-group-sm > input:not(textarea)[type="text"],
.form-group .input-group-sm > input:not(textarea)[type="email"],
.form-group .input-group-sm > input:not(textarea)[type="password"],
.form-group .input-group-sm > textarea:not(textarea),
.form-group .input-group-sm > select:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8125rem + 2px); }

.input-group-sm > .form-control, .form-group .input-group-sm > input[type="text"],
.form-group .input-group-sm > input[type="email"],
.form-group .input-group-sm > input[type="password"],
.form-group .input-group-sm > textarea,
.form-group .input-group-sm > select,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 1rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 2rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 2rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 2rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 2rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(73, 80, 87, 0.9);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.3; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e5e5e5;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #285492 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1d3d6a !important; }

.bg-secondary {
  background-color: #7ac143 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #629d34 !important; }

.bg-success {
  background-color: #5cb85c !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #449d44 !important; }

.bg-info {
  background-color: #5bc0de !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #31b0d5 !important; }

.bg-warning {
  background-color: #f0ad4e !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ec971f !important; }

.bg-danger {
  background-color: #d9534f !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c9302c !important; }

.bg-light {
  background-color: #e9ecef !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cbd3da !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-top {
  border-top: 1px solid #e9ecef !important; }

.border-right {
  border-right: 1px solid #e9ecef !important; }

.border-bottom {
  border-bottom: 1px solid #e9ecef !important; }

.border-left {
  border-left: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #285492 !important; }

.border-secondary {
  border-color: #7ac143 !important; }

.border-success {
  border-color: #5cb85c !important; }

.border-info {
  border-color: #5bc0de !important; }

.border-warning {
  border-color: #f0ad4e !important; }

.border-danger {
  border-color: #d9534f !important; }

.border-light {
  border-color: #e9ecef !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.1rem !important; }

.rounded {
  border-radius: 0.15rem !important; }

.rounded-top {
  border-top-left-radius: 0.15rem !important;
  border-top-right-radius: 0.15rem !important; }

.rounded-right {
  border-top-right-radius: 0.15rem !important;
  border-bottom-right-radius: 0.15rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.15rem !important;
  border-bottom-left-radius: 0.15rem !important; }

.rounded-left {
  border-top-left-radius: 0.15rem !important;
  border-bottom-left-radius: 0.15rem !important; }

.rounded-lg {
  border-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1030; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: normal !important; }

.font-weight-bold {
  font-weight: bold !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #285492 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #183156 !important; }

.text-secondary {
  color: #7ac143 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #568a2e !important; }

.text-success {
  color: #5cb85c !important; }

a.text-success:hover, a.text-success:focus {
  color: #3d8b3d !important; }

.text-info {
  color: #5bc0de !important; }

a.text-info:hover, a.text-info:focus {
  color: #28a1c5 !important; }

.text-warning {
  color: #f0ad4e !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #df8a13 !important; }

.text-danger {
  color: #d9534f !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #b52b27 !important; }

.text-light {
  color: #e9ecef !important; }

a.text-light:hover, a.text-light:focus {
  color: #bdc6cf !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #285492 !important; }

.text-muted {
  color: #ced4da !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.text-xs-left {
  text-align: left !important; }

.text-xs-right {
  text-align: right !important; }

.text-xs-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.alert, .error_message,
.success_message,
.info_message,
.warn_message,
.message, .formErrors {
  color: #495057;
  background-color: #F3F7FA;
  border-color: #868e96;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.15rem; }
  .alert hr, .error_message hr, .success_message hr, .info_message hr, .warn_message hr, .message hr, .formErrors hr {
    border-top-color: #78818a; }
  .alert .alert-link, .error_message .alert-link, .success_message .alert-link, .info_message .alert-link, .warn_message .alert-link, .message .alert-link, .formErrors .alert-link {
    color: #32373b; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible {
  padding-right: 2.5rem; }
  .alert-dismissible .close {
    position: relative;
    top: -.125rem;
    right: -1.25rem;
    color: inherit; }

.alert-success {
  color: #528253;
  background-color: #f0f4e3;
  border-color: #dce6be; }
  .alert-success hr {
    border-top-color: #d3deac; }
  .alert-success .alert-link {
    color: #3e633f; }

.alert-info {
  color: #31708f;
  background-color: #f3f6fb;
  border-color: #d8e2f2; }
  .alert-info hr {
    border-top-color: #c5d4ec; }
  .alert-info .alert-link {
    color: #245269; }

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faf2cc; }
  .alert-warning hr {
    border-top-color: #f7ecb5; }
  .alert-warning .alert-link {
    color: #66512c; }

.alert-danger, .formErrors {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebcccc; }
  .alert-danger hr, .formErrors hr {
    border-top-color: #e4b9b9; }
  .alert-danger .alert-link, .formErrors .alert-link {
    color: #843534; }

.success_message {
  color: #528253;
  background-color: #f0f4e3;
  border-color: #dce6be; }
  .success_message hr {
    border-top-color: #d3deac; }
  .success_message .alert-link {
    color: #3e633f; }

.info_message {
  color: #31708f;
  background-color: #f3f6fb;
  border-color: #d8e2f2; }
  .info_message hr {
    border-top-color: #c5d4ec; }
  .info_message .alert-link {
    color: #245269; }

.error_message,
.warn_message {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faf2cc; }
  .error_message hr,
  .warn_message hr {
    border-top-color: #f7ecb5; }
  .error_message .alert-link,
  .warn_message .alert-link {
    color: #66512c; }

.btn {
  cursor: default; }

.btn-upperCase {
  font-size: .92rem;
  font-weight: bold;
  letter-spacing: .04rem;
  text-transform: uppercase; }

.btn-secondary {
  color: #fff; }
  .btn-secondary:hover, .btn-secondary:focus {
    color: #fff; }

.btn-light {
  color: #365e97; }

.btn-link {
  cursor: pointer; }

label {
  display: block; }

fieldset {
  margin: 0 0 2em;
  padding: 1rem;
  position: relative;
  border: 1px solid #e9ecef; }

.form-alerts ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.form-alerts li {
  border: 1px solid transparent;
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faf2cc;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem; }
  .form-alerts li hr {
    border-top-color: #f7ecb5; }
  .form-alerts li .alert-link {
    color: #66512c; }

.form-control[readonly], .form-group input[readonly][type="text"],
.form-group input[readonly][type="email"],
.form-group input[readonly][type="password"],
.form-group textarea[readonly],
.form-group select[readonly] {
  background-color: #f3f6f9; }

.form-check-label {
  padding-left: 1.75rem; }

.form-check-input {
  margin-left: -1.75rem; }

.form-group.form-group--inline input {
  display: inline-block;
  width: auto;
  vertical-align: middle; }

.fieldLabel {
  cursor: pointer; }
  .req .fieldLabel:after {
    content: ' *'; }

.radio,
.checkBox {
  position: relative; }
  .radio label,
  .checkBox label {
    display: block;
    width: auto;
    padding-left: 1.5em;
    *zoom: 1;
    *padding-left: 0;
    font-weight: normal;
    vertical-align: middle; }
  .radio input,
  .checkBox input {
    padding: 0;
    float: left;
    position: relative;
    top: .2em;
    border: 0;
    line-height: 1.5em;
    vertical-align: middle; }
  .radio input[type="radio"],
  .checkBox input[type="radio"] {
    top: .2em; }

/** webkit fix */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .radio input[type="radio"] {
    left: 1px; } }

/**
 * Error handling
 */
.formErrors ul {
  margin: 0;
  padding: 0; }

.formErrors li {
  list-style-type: none; }

.fieldError {
  padding: 0.5rem 0; }

.error {
  color: #d9534f; }

.error .fieldLabel {
  font-weight: bold; }

.error input {
  border-color: #d9534f; }

.list-tick {
  padding-left: 0;
  list-style-type: none; }
  .list-tick li {
    padding: .3rem;
    padding-left: 37px;
    position: relative; }
  .list-tick li:before {
    background-image: url(sprite.png);
    background-position: 0px -258px;
    width: 25px;
    height: 17px;
    content: '';
    display: inline-block;
    position: absolute;
    left: 4px;
    vertical-align: middle; }

.list-divider {
  padding: 0;
  padding-right: 3rem;
  list-style: none; }
  .list-divider li {
    padding: 1rem .8rem;
    border-bottom: 1px solid #e9ecef; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.menu-collapsible .menu-sublist {
  display: none;
  animation: MenuCollapsibleOut 1.5s;
  overflow: hidden; }

.menu-collapsible .active + .menu-sublist {
  display: block;
  animation: MenuCollapsibleIn 1.5s; }

.menu-collapsible .menu-sublist .menu-sublist {
  display: block; }

@keyframes MenuCollapsibleIn {
  0% {
    display: none;
    max-height: 0; }
  1% {
    display: block; }
  100% {
    max-height: 1000px; } }

.menu-multilevel {
  /* Hide the inner submenus */
  /*
  When a submenu is openend, we will hide all li siblings.
  For that we give a class to the parent menu called "dl-subview".
  We also hide the submenu link.
  The opened submenu will get the class "dl-subviewopen".
  All this is done for any sub-level being entered.
  */
  /* Dynamically added submenu outside of the menu context */ }
  .menu-multilevel ul {
    transform-style: preserve-3d; }
  .menu-multilevel .menu-item,
  .menu-multilevel .menu-link {
    display: block;
    position: relative; }
  .menu-multilevel .mml-menu {
    position: absolute;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    backface-visibility: hidden; }
    .menu-multilevel .mml-menu.mml-toggle {
      transition: all .3s ease; }
    .menu-multilevel .mml-menu.mml-open {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0); }
    .menu-multilevel .mml-menu.animate-out {
      animation: MenuAnimOut 0.3s ease-in-out; }
    .menu-multilevel .mml-menu.animate-in {
      animation: MenuAnimIn 0.3s ease-in-out; }
  .menu-multilevel .mml-back > .menu-link {
    background: rgba(0, 0, 0, 0.1); }
  .menu-multilevel .mml-back:after,
  .menu-multilevel .menu-item > .menu-link:not(:only-child):after {
    content: '>';
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -.75rem;
    color: inherit;
    speak: none; }
  .menu-multilevel .mml-back:after {
    content: '<';
    color: #fff; }
  .menu-multilevel .menu-item .menu-sublist {
    display: none; }
  .menu-multilevel .mml-menu.mml-subview .menu-item,
  .menu-multilevel .mml-menu.mml-subview .menu-item.mml-subviewopen > .menu-link,
  .menu-multilevel .mml-menu.mml-subview .menu-item.mml-subview > .menu-link {
    display: none; }
  .menu-multilevel .mml-menu.mml-subview .menu-item.mml-subview,
  .menu-multilevel .mml-menu.mml-subview .menu-item.mml-subview .mml-submenu,
  .menu-multilevel .mml-menu.mml-subview .menu-item.mml-subviewopen,
  .menu-multilevel .mml-menu.mml-subview .menu-item.mml-subviewopen > .mml-submenu,
  .menu-multilevel .mml-menu.mml-subview .menu-item.mml-subviewopen > .mml-submenu > .menu-item {
    display: block; }
  .menu-multilevel > .mml-submenu {
    position: absolute;
    width: 100%;
    left: 0;
    margin: 0;
    z-index: 1; }
  .menu-multilevel > .menu-sublist.animate-out {
    animation: SubMenuAnimOut 0.3s ease-in-out; }
  .menu-multilevel > .menu-sublist.animate-in {
    animation: SubMenuAnimIn 0.3s ease-in-out; }

@keyframes MenuAnimOut {
  0% { }
  100% {
    opacity: 0;
    transform: translateX(-100%); } }

@keyframes MenuAnimIn {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes SubMenuAnimOut {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(100%); } }

@keyframes SubMenuAnimIn {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

html,
body,
.mp-push-container,
.mp-pusher,
.scroller {
  height: 100%;
  position: relative; }

.mp-push-container {
  overflow: hidden; }

.scroller {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }

.mp-trigger {
  display: inline-block;
  margin: .5rem;
  padding: 0;
  position: relative;
  height: 2.5rem;
  width: 3rem;
  border: 0;
  background: none;
  cursor: pointer; }

.mp-pusher {
  left: 0; }
  .mp-pusher.mp-pushed {
    cursor: pointer;
    transform: translate3d(20rem, 0, 0); }

.mp-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 20rem;
  transform: translate3d(-100%, 0, 0); }

.mp-level {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  transform: translate3d(-100%, 0, 0);
  -webkit-overflow-scrolling: touch; }

/* overlays for pusher and for level that gets covered */
.mp-pusher::after,
.mp-level::after,
.mp-level::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  width: 0;
  opacity: 0;
  cursor: pointer; }

.mp-pusher::after,
.mp-level::after {
  background: rgba(0, 0, 0, 0.3);
  transition: opacity .3s, width .1s .3s, height .1s .3s; }

.mp-level::after {
  z-index: -1; }

.mp-pusher.mp-pushed::after,
.mp-level.mp-level-overlay::after {
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: opacity .3s; }

.mp-level.mp-level-overlay {
  cursor: pointer; }

.mp-level.mp-level-overlay.mp-level::before {
  height: 100%;
  width: 100%;
  background: transparent;
  opacity: 1; }

.mp-pusher,
.mp-level {
  transition: all .4s; }

/* overlap */
.mp-overlap .mp-level.mp-level-open {
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
  transform: translate3d(-40px, 0, 0); }

/* First level */
.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
  box-shadow: none;
  transform: translate3d(0, 0, 0); }

/* cover */
.mp-cover .mp-level.mp-level-open {
  transform: translate3d(0, 0, 0); }

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
  transform: translate3d(-100%, 0, 0); }

.mp-level-open:not(.mp-level-overlay) {
  overflow-y: auto; }

/* content style */
.mp-menu ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.mp-menu ul li > a.menu-link {
  display: block;
  padding: 1.5rem 1.5rem;
  outline: none;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.1);
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  font-size: 1.2rem;
  transition: background .3s, box-shadow .3s; }

.mp-menu ul li::before {
  position: absolute;
  left: 10px;
  z-index: -1;
  color: rgba(0, 0, 0, 0.2);
  line-height: 3.5; }

.mp-level > ul > li:first-child > a {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.1), inset 0 2px rgba(0, 0, 0, 0.1); }

.mp-menu ul li a:hover,
.mp-level > ul > li:first-child > a:hover {
  background: rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0); }

.mp-menu .mp-level.mp-level-overlay > ul > li > a,
.mp-level.mp-level-overlay > ul > li:first-child > a {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0); }

.mp-level > ul > li:first-child > a:hover,
.mp-level.mp-level-overlay > ul > li:first-child > a {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0), inset 0 1px rgba(0, 0, 0, 0); }

/* seems like Chrome 34..1847.131 needs the second shadow otherwise the transition breaks */
.mp-back {
  display: block;
  padding: 1.5rem 1.5rem;
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  outline: none;
  text-transform: uppercase;
  transition: background .3s; }

.mp-back::after {
  content: '<';
  position: absolute;
  right: 10px;
  font-size: 1.3em;
  color: rgba(0, 0, 0, 0.2); }

.mp-menu .mp-level.mp-level-overlay > .mp-back,
.mp-menu .mp-level.mp-level-overlay > .mp-back::after {
  background: transparent;
  box-shadow: none;
  color: transparent; }

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
/* We'll show the first level only */
.newsticker {
  margin: 1rem 0;
  border: 1px solid #e9ecef;
  background: #e9ecef;
  background: linear-gradient(to bottom, #fefefe 0%, #fefeff 100%);
  /* W3C */ }

.newsticker .list {
  line-height: 160%;
  padding: 1.5rem; }
  @media (min-width: 992px) {
    .newsticker .list {
      height: 8rem;
      padding: 1.5rem 1.5rem 1.5rem 8rem;
      background: url(https://static.medi-king.de/images/icons/icon_news.png) no-repeat 20px 20px; } }

.newsticker .list span {
  font-size: 11px;
  font-weight: bold;
  margin-right: 7px; }

.newsticker .list img {
  float: right;
  padding: 0 0 20px 20px; }

.productList {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin: 0 -5px;
  padding: 0;
  list-style: none; }

.productListItem {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 1rem;
  position: relative; }

.productListItem-link {
  display: block;
  height: 100%;
  position: relative;
  background-color: #F3F7FA;
  border: 1px solid #dee2e6; }
  .productListItem-link:hover, .productListItem-link:focus {
    border-color: #ced4da;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    text-decoration: none; }

.productListItem-picture {
  padding: 0.5rem;
  background-color: #fff;
  text-align: center; }
  .productListItem-picture img {
    margin: auto; }

.productListItem-details {
  padding: .5rem;
  color: #285492;
  font-size: .92rem; }

.productListItem-title {
  height: 2.16rem;
  height: 3.24rem;
  font-size: .92rem;
  line-height: 1.2;
  overflow: hidden; }

.productListItem-title,
.productListItem-price {
  margin-bottom: .5rem; }

.productListItem-price,
.productListItem-bottom {
  text-align: right; }

.productListItem-price {
  font-size: 1.17rem;
  font-weight: bold; }

.productListItem-price-discounted {
  color: #495057; }

.productListItem-price-bottom {
  font-size: .75rem; }

.ribbon {
  padding: .5rem 0;
  position: absolute;
  top: .75rem;
  right: -5px;
  width: 3rem;
  background-color: #7ac143;
  color: #fff;
  line-height: 100%;
  text-align: center; }
  .ribbon:before, .ribbon:after {
    content: '';
    position: absolute; }
  .ribbon:before {
    top: 0;
    left: -2rem;
    border-style: solid;
    border-width: 0 2rem 2rem 0;
    border-color: transparent #7ac143 transparent transparent; }
  .discounted .ribbon {
    background-color: #aa1212; }
    .discounted .ribbon:before {
      border-color: transparent #aa1212 transparent transparent; }
  .ribbon:after {
    top: -6px;
    right: 0;
    border-style: solid;
    border-width: 5px 0 0 5px;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.4); }

.pdf-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin: 0 -5px;
  padding: 0; }

.pdf-item {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 1rem !important; }

.pdf-item_inner {
  background-color: #F3F7FA;
  border: 1px solid #dee2e6; }
  .pdf-item_inner:hover, .pdf-item_inner:focus {
    border-color: #ced4da;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    text-decoration: none; }

.pdf-item_imgWrapper {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  background-color: #fff; }
  .pdf-item_imgWrapper img {
    margin: auto; }

.pdf-item_details {
  padding: .5rem;
  color: #285492;
  font-size: .92rem; }

.pdf-item_title {
  height: 3.24rem;
  font-size: .92rem;
  line-height: 1.2;
  overflow: hidden;
  margin-bottom: .5rem;
  text-overflow: ellipsis; }

.pdf-item_description,
.pdf-item_link {
  display: none; }

#teaser {
  margin: 2rem 0 3rem;
  padding: 0; }
  #teaser:not(.slick-slider) {
    list-style: none; }
    #teaser:not(.slick-slider) li {
      display: none; }
      #teaser:not(.slick-slider) li:first-child {
        display: block; }
  #teaser img {
    width: 100%; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before, .slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slider li,
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slider li:first-child {
  display: block; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  z-index: 1;
  display: block;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:not(.slick-disabled):hover:before,
.slick-next:not(.slick-disabled):hover:before,
.slick-slider:hover .slick-prev:not(.slick-disabled):before,
.slick-slider:hover .slick-next:not(.slick-disabled):before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  content: '';
  display: block;
  opacity: .5;
  color: white;
  transition: all .5s; }

.slick-prev {
  left: -18px; }

.slick-prev:before {
  background-image: url(sprite.png);
  background-position: 0px -1030px;
  width: 36px;
  height: 36px; }

.slick-next {
  right: -18px; }

.slick-next:before {
  background-image: url(sprite.png);
  background-position: 0px -1076px;
  width: 36px;
  height: 36px; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: '';
  text-align: center;
  opacity: .25;
  background-color: #3f6498;
  border-radius: 50%; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  background-color: #3f6498; }

.nav-tabs {
  display: flex;
  padding: 0;
  margin-bottom: -1px;
  list-style: none; }
  .nav-tabs .nav-item {
    display: inline-block; }
  .nav-tabs .nav-link {
    display: block;
    padding: 1rem 0;
    width: 12rem;
    position: relative;
    z-index: 1;
    background: #F3F7FA;
    border: 1px solid #e9ecef;
    border-bottom: 1px solid #ced4da;
    text-align: center; }
    .nav-tabs .nav-link.active {
      background-color: #fff;
      border-color: #ced4da;
      border-bottom-color: #fff; }
    .nav-tabs .nav-link.disabled {
      color: #adb5bd; }
      .nav-tabs .nav-link.disabled:hover {
        text-decoration: none; }

.tab-pane {
  display: none;
  padding: 1rem;
  border: 1px solid #ced4da; }
  .tab-pane.active {
    display: block; }

.modal-content {
  border-width: .75rem; }

.modal-footer {
  background-color: #F3F7FA; }

html {
  font-size: 12px; }

body {
  background: #CECFD1;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.125), rgba(0, 0, 0, 0) 400px), linear-gradient(to top, rgba(0, 0, 0, 0.125), rgba(0, 0, 0, 0) 400px), url("../images/bg.gif"); }

.wrapper {
  background-color: #fff; }
  @media (min-width: 992px) {
    .wrapper {
      width: 996px;
      margin: auto;
      border: 4px solid rgba(0, 0, 0, 0.15);
      border-width: 0 4px; } }

img {
  max-width: 100%; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

nav ul,
nav ol {
  margin: 0;
  padding: 0;
  list-style: none; }

svg {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
  fill: currentColor; }

svg.icon {
  height: 1.5em;
  width: 1.5em;
  vertical-align: middle; }

button .icon {
  margin-top: -.1em; }

/*
  #Header / #Banner
 */
.brand {
  margin: 0 1rem;
  width: 150px;
  max-width: 80%; }
  @media (min-width: 992px) {
    .brand {
      height: 100px;
      width: 245px;
      margin: 1rem 2.5rem 1rem 0;
      max-width: none; }
      .brand a {
        display: block;
        margin: 0;
        width: 100%;
        height: 100%;
        background-image: url("../images/medi-king.svg");
        background-repeat: no-repeat;
        background-position: center; }
      .brand img {
        display: none; } }

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0 -1rem 1rem;
  position: relative;
  z-index: 1000;
  background-color: #fff;
  border-bottom: 3px solid #7ac143; }
  @media (min-width: 992px) {
    .banner {
      padding: 0;
      border-bottom: 0; } }
  .store--b2c .banner {
    border-bottom-color: #285492; }

.mp-trigger,
.search-trigger,
.cart-trigger {
  margin: 0;
  padding: .75rem;
  height: 3.5rem;
  width: 3.5rem;
  background: none;
  border: 0;
  color: #002e6e;
  cursor: pointer; }

.mp-trigger {
  flex-shrink: 0; }

.banner-mobile-utility {
  display: none;
  white-space: nowrap; }
  @media (max-width: 991.98px) {
    .banner-mobile-utility {
      display: block; } }

.banner-wrapper {
  flex-shrink: 0; }

.banner-utility {
  display: none; }

@media (min-width: 992px) {
  .banner-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1; }
    .banner-wrapper > div {
      display: flex;
      position: relative;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%; }
  .banner-utility {
    display: block; } }

#searchForm {
  margin: 1rem 0 0;
  padding: 1rem 1.25rem;
  position: relative;
  background: #f3f6fb;
  border: 1px solid #e9eae6; }
  @media (min-width: 992px) {
    #searchForm {
      max-width: 100%; } }

.searchForm-inputWrapper {
  display: flex; }
  @media (min-width: 992px) {
    .searchForm-inputWrapper {
      display: block; } }

.search-field {
  margin: -2px 0 0 0;
  padding: .6rem;
  padding-left: 33px;
  width: 100%;
  max-width: 24rem;
  flex-grow: 1;
  background: #fff url("sprite.png") no-repeat;
  background-position: 12px -97px;
  border: 1px solid #cdcec6;
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.05);
  color: #495057;
  line-height: 1; }
  @media (min-width: 992px) {
    .search-field {
      width: 24rem; } }

.search-submit {
  padding: .75rem;
  margin: 0 0 0 .5rem;
  background-color: #7ac143;
  background-image: linear-gradient(to bottom, #9fd00c 0%, #8ebd00 100%);
  border: 1px solid #7ca500;
  border-radius: 2px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  font-size: .92rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase; }
  .search-submit:hover {
    background: #8ebd00;
    background: linear-gradient(to bottom, #8ebd00 1%, #9fd00c 100%);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1); }

.search-results {
  position: absolute;
  top: 100%;
  left: 1.25rem;
  width: 100%;
  max-width: 24rem;
  z-index: 1; }
  @media (max-width: 991.98px) {
    .search-results {
      left: 0;
      max-width: none; }
      .search-results .ui-menu {
        top: 0 !important;
        left: 0 !important; } }
  .search-results ul {
    margin: 0;
    padding: 0;
    border: 1px solid #e9ecef;
    list-style: none; }
  .search-results .ui-menu-item {
    border-bottom: 1px solid #e9ecef; }
    .search-results .ui-menu-item:last-child {
      border-bottom: 0;
      text-align: center; }
      .search-results .ui-menu-item:last-child a {
        display: inline-block; }
  .search-results .ui-menu-item-wrapper {
    display: flex;
    padding: 0.5rem;
    width: 100%;
    background-color: #fff; }
    .search-results .ui-menu-item-wrapper:hover {
      text-decoration: none; }
  .search-results .product-image {
    padding: 0 .5rem;
    width: 60px;
    flex-shrink: 0; }
  .search-results .product-meta {
    flex-grow: 1;
    margin-left: 1rem; }
  .search-results .name {
    font-weight: bold; }
  .search-results .category {
    color: #495057;
    font-size: .92rem; }

.smallCart {
  margin: 1rem 0 0;
  position: relative; }

.smallCart-link {
  display: block;
  height: 48px;
  margin: 1rem 0;
  padding: 0 1rem;
  padding-left: 108px;
  position: relative;
  background-image: url("sprite.png");
  background-position: 0px -1122px;
  background-repeat: no-repeat;
  text-align: right; }
  .smallCart-link:hover {
    text-decoration: none; }
  .smallCart-link h2 {
    margin: 0;
    padding-top: 1rem;
    color: #7ac143;
    font-size: .92rem;
    font-weight: bold; }
  .smallCart-link .count-items {
    padding: .25rem .3rem;
    position: absolute;
    top: 10px;
    left: 43px;
    background-color: #7ac143;
    color: #fff;
    font-size: .92rem;
    font-weight: bold;
    line-height: 1; }
  @media (min-width: 992px) {
    .smallCart-link {
      margin: 0; } }

.cart-trigger {
  display: inline-block;
  position: relative; }
  .cart-trigger[data-count]::before {
    content: attr(data-count);
    align-items: center;
    top: 2px;
    display: flex;
    height: 1.5rem;
    width: 1.5rem;
    justify-content: center;
    position: absolute;
    left: 0;
    background-color: #7ac143;
    border: 2px solid #fff;
    border-radius: 9999px;
    color: #fff;
    font-size: .8rem;
    line-height: 0;
    font-weight: 600; }

#overlay {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1); }
  #overlay.active {
    display: block; }

@media (min-width: 992px) {
  .smallCart-link {
    display: inline-block; } }

.ui-menu-item a img {
  border: 1px solid #fff; }

.ui-menu-item a:hover, .ui-menu-item a:focus {
  background-color: #F3F7FA;
  text-decoration: none; }
  .ui-menu-item a:hover img, .ui-menu-item a:focus img {
    border-color: #e9ecef; }

.search-results .ui-menu {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15); }

.ui-helper-hidden-accessible {
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  width: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0); }

@media (max-width: 991.98px) {
  #searchForm,
  .smallCart {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0; }
    #searchForm.active,
    .smallCart.active {
      display: block;
      z-index: 1000; }
  #searchForm {
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1); } }

/*
  #Menus
 */
.menu-item {
  display: inline-block; }

.menu-link {
  display: block; }

nav.mobile {
  display: block; }
  @media (min-width: 992px) {
    nav.mobile {
      display: none; } }
  nav.mobile .menu-item {
    display: block; }
  nav.mobile a {
    color: inherit; }

nav.primary {
  display: none;
  margin-bottom: 1rem;
  justify-content: space-between;
  background-color: #285492;
  background-image: linear-gradient(to bottom, #285492, #002e6e);
  color: #fff; }
  nav.primary .menu-link {
    color: currentColor; }
  nav.primary .menu-link--sale {
    font-weight: bold;
    color: #f33; }
  @media (min-width: 992px) {
    nav.primary {
      display: flex; } }

nav.primary .menu-primary {
  display: flex; }
  nav.primary .menu-primary > .menu-item > .menu-link {
    padding: .92rem 1.5rem;
    border-right: 1px solid #2e4b75;
    box-shadow: 1px 0 0 #6986b0;
    font-size: 13px;
    line-height: 1;
    text-align: center; }
  nav.primary .menu-primary > .menu-item:hover > a {
    background-color: #365e97; }
  nav.primary .menu-primary .menu-item {
    position: relative; }
  nav.primary .menu-primary .menu-link:hover,
  nav.primary .menu-primary .menu-link:focus {
    text-decoration: none; }
  nav.primary .menu-primary .menu-item:hover > .menu-submenu,
  nav.primary .menu-primary .menu-submenu:hover {
    display: block; }
  nav.primary .menu-primary .menu-submenu {
    display: none;
    padding: 0;
    position: absolute;
    left: 0;
    z-index: 1000;
    color: #285492;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15); }
    nav.primary .menu-primary .menu-submenu:after, nav.primary .menu-primary .menu-submenu:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 23px;
      height: 0;
      width: 0;
      border: 1px solid transparent;
      border-width: 12px 13px 0 13px;
      border-top-color: #365e97;
      z-index: 3; }
    nav.primary .menu-primary .menu-submenu:after {
      border-width: 14px 14px 0 14px;
      border-top-color: #fff;
      z-index: 2;
      left: 22px;
      top: 0; }
    nav.primary .menu-primary .menu-submenu .menu-item {
      display: block; }
    nav.primary .menu-primary .menu-submenu .menu-item:hover > .menu-link,
    nav.primary .menu-primary .menu-submenu .menu-submenu .menu-link {
      background: #F4F6FB; }
    nav.primary .menu-primary .menu-submenu .menu-item:hover > .menu-link {
      font-weight: bold; }
    nav.primary .menu-primary .menu-submenu .menu-link {
      padding: .83rem 2rem .83rem 5rem;
      width: 20rem;
      background: #E6ECF4;
      background: rgba(230, 236, 244, 0.95); }
    nav.primary .menu-primary .menu-submenu .menu-submenu {
      top: 0;
      left: 100%; }
      nav.primary .menu-primary .menu-submenu .menu-submenu:after, nav.primary .menu-primary .menu-submenu .menu-submenu:before {
        display: none; }

nav.primary .menu-primary + div {
  display: flex;
  height: 27px;
  flex-wrap: nowrap;
  align-self: center; }

nav.primary .menu-secondary .menu-item {
  padding: .3rem 0; }

nav.primary .menu-secondary .menu-link {
  display: inline-block;
  margin-right: .5rem;
  padding-right: .5rem;
  font-size: 11px; }

nav.primary .menu-secondary .menu-item:not(:last-child) .menu-link {
  border-right: 1px solid #345e99;
  box-shadow: 1px 0 0 #14335e; }

.menu-footer {
  padding: 0;
  margin-bottom: 0; }
  .menu-footer .menu-item--category {
    margin-top: 1rem; }
  .menu-footer .menu-submenu {
    margin: 1rem 0 0;
    padding: 0; }
  .menu-footer .menu-item {
    display: block; }
  .menu-footer .menu-link {
    display: block;
    padding: .5rem 0;
    color: currentColor; }
    @media (min-width: 768px) {
      .menu-footer .menu-link {
        padding: .25rem 0; } }
    @media (min-width: 992px) {
      .menu-footer .menu-link {
        padding: 0; } }

.menu-banner {
  color: #495057;
  background-color: #F3F7FA;
  border-color: #868e96;
  position: relative;
  border-bottom: 1px solid #c4c5c7; }
  .menu-banner hr {
    border-top-color: #78818a; }
  .menu-banner .alert-link {
    color: #32373b; }

nav.bannerMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1001; }
  @media (max-width: 991.98px) {
    nav.bannerMenu .company {
      display: none; } }
  @media (max-width: 767.98px) {
    nav.bannerMenu .hotline {
      display: none; } }

.bannerMenu__item {
  display: inline-block;
  position: relative; }

.bannerMenu__link {
  display: block;
  padding: 0.5rem;
  border-top: 3px solid transparent; }
  .store--b2b .bannerMenu__link--b2b,
  .store--b2c .bannerMenu__link--b2c, .bannerMenu__link:hover {
    border-top-color: currentColor;
    text-decoration: none; }
  .bannerMenu__link--b2c {
    opacity: .6; }

.bannerMenu__item__info {
  color: #495057;
  background-color: #F3F7FA;
  border-color: #ced4da;
  padding: .5em .75em;
  position: absolute;
  top: 3.5em;
  width: 18em;
  z-index: 1070;
  margin-left: -50%;
  border-width: 1px;
  border-style: solid;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  transition: opacity .3s;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  text-align: center; }
  .bannerMenu__item__info hr {
    border-top-color: #bfc7cf; }
  .bannerMenu__item__info .alert-link {
    color: #32373b; }
  .bannerMenu__item__info:before, .bannerMenu__item__info:after {
    content: '';
    display: block;
    bottom: 100%;
    position: absolute;
    border: solid transparent; }
  .bannerMenu__item__info:after {
    left: 50%;
    margin-left: -8px;
    border-bottom-color: #F3F7FA;
    border-width: 8px; }
  .bannerMenu__item__info:before {
    left: 50%;
    margin-left: -9px;
    border-bottom-color: #ced4da;
    border-width: 9px; }
  .bannerMenu__link.hover + .bannerMenu__item__info,
  .bannerMenu__link:hover + .bannerMenu__item__info {
    visibility: visible;
    opacity: 1; }

.menu-company {
  padding: 0.25rem 0;
  font-size: .92rem; }
  .menu-company .menu-link {
    display: inline-block;
    padding-left: 1.5rem; }

/*
  #Categories
 */
nav.primary .menu-submenu .menu-item {
  position: relative; }
  nav.primary .menu-submenu .menu-item:before {
    content: '';
    display: none;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    margin-top: -10.5px;
    height: 21px;
    width: 21px;
    background-image: url("sprite.png");
    background-repeat: no-repeat;
    pointer-events: none; }
  nav.primary .menu-submenu .menu-item.nav_atmung:before {
    background-position: 0 -375px; }
  nav.primary .menu-submenu .menu-item.nav_atmung:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_herz-kreislauf:before {
    background-position: 0 -530px; }
  nav.primary .menu-submenu .menu-item.nav_herz-kreislauf:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_diagnostik:before {
    background-position: 0 -468px; }
  nav.primary .menu-submenu .menu-item.nav_diagnostik:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_wundversorgung:before {
    background-position: 0 -871px; }
  nav.primary .menu-submenu .menu-item.nav_wundversorgung:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_instrumente:before {
    background-position: 0 -654px; }
  nav.primary .menu-submenu .menu-item.nav_instrumente:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_immobilisation:before {
    background-position: 0 -623px; }
  nav.primary .menu-submenu .menu-item.nav_immobilisation:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_taschen-koffer:before {
    background-position: 0 -809px; }
  nav.primary .menu-submenu .menu-item.nav_taschen-koffer:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_alltagstauglich:before {
    background-position: 0 -344px; }
  nav.primary .menu-submenu .menu-item.nav_alltagstauglich:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_hygiene:before {
    background-position: 0 -592px; }
  nav.primary .menu-submenu .menu-item.nav_hygiene:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_technische-rettung:before {
    background-position: 0 -840px; }
  nav.primary .menu-submenu .menu-item.nav_technische-rettung:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_ausbildung:before {
    background-position: 0 -406px; }
  nav.primary .menu-submenu .menu-item.nav_ausbildung:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_dokumentation:before {
    background-position: 0 -406px; }
  nav.primary .menu-submenu .menu-item.nav_dokumentation:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_erste-hilfe:before {
    background-position: 0 -499px; }
  nav.primary .menu-submenu .menu-item.nav_erste-hilfe:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_military:before {
    background-position: 0 -685px; }
  nav.primary .menu-submenu .menu-item.nav_military:hover:before {
    display: block; }
  nav.primary .menu-submenu .menu-item.nav_humanitaere_hilfe:before {
    background-position: 0 -561px; }
  nav.primary .menu-submenu .menu-item.nav_humanitaere_hilfe:hover:before {
    display: block; }

/*
  #Footer
 */
.site-footer {
  margin-top: 3rem;
  color: #212529; }
  .site-footer a,
  .site-footer a:hover {
    color: inherit; }
  .site-footer ul a {
    display: block; }

.shop-information,
nav.footer {
  margin-top: 3rem;
  padding-top: 1rem; }

.shop-information {
  background-color: #fff;
  background-image: linear-gradient(to bottom, #fcfdfd, #fff);
  border-top: 1px solid #eff0ee; }
  .shop-information [class^="col"]:nth-child(n+2) {
    position: relative; }
    .shop-information [class^="col"]:nth-child(n+2):before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px;
      background-color: #e9ecef;
      background: transparent linear-gradient(to bottom, rgba(233, 236, 239, 0), #e9ecef 20%, #e9ecef 80%, rgba(233, 236, 239, 0)); }
  .shop-information .payment {
    margin: 0;
    padding: 0; }
    .shop-information .payment li {
      display: inline-block;
      margin: 0;
      margin-bottom: 0.66667rem;
      width: 11rem;
      border: 1px solid #e9ecef;
      text-align: center; }
    .shop-information .payment li:nth-child(2n+1) {
      margin-right: 0.66667rem; }
    .shop-information .payment a {
      display: block;
      padding: .75rem 0;
      color: #285492; }
      .shop-information .payment a:hover {
        text-decoration: none; }
    .shop-information .payment .paypal {
      background: url("sprite.png") no-repeat 60% -275px; }
      .shop-information .payment .paypal a {
        color: rgba(255, 255, 255, 0); }

.social-links {
  margin: 0;
  padding: 0;
  list-style: none; }
  .social-links li {
    display: inline-block;
    margin-right: 1rem; }
  .social-links a {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    display: block; }
  .social-links .facebook {
    background-image: url(sprite.png);
    background-position: 0px -902px;
    width: 85px;
    height: 32px; }
  .social-links .twitter {
    background-image: url(sprite.png);
    background-position: 0px -987px;
    width: 82px;
    height: 33px; }

.legal {
  padding: 1.5rem 0;
  color: #495057;
  font-size: .92rem; }

.footer-headline {
  margin: 1rem 0;
  color: #7ac143;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 1.1rem;
  font-weight: bold; }

.checkout .legal,
.error .legal {
  border-top: 1px solid #e9ecef;
  background-color: #F3F7FA; }

/*
  #Misc
 */
.spinner {
  width: 40px;
  height: 40px;
  margin: 1rem auto;
  background-color: #285492;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

a.underline {
  text-decoration: underline; }

a.highlight,
.btn-link.highlight {
  color: #7ac143;
  text-decoration: underline; }
  a.highlight:hover,
  .btn-link.highlight:hover {
    text-decoration: none; }

.flex {
  display: flex; }

.flex-wrap {
  flex-wrap: wrap; }

.space-between {
  justify-content: space-between; }

.space-around {
  justify-content: space-around; }

.grow {
  flex-grow: 1; }

.flex-start,
.align-start {
  align-items: flex-start; }

.align-center {
  align-items: center; }

.ui-icon-trash {
  display: inline-block;
  background-image: url(sprite.png);
  background-position: 0px -20px;
  width: 9px;
  height: 10px; }

.btn-remove {
  background-color: #F3F7FA;
  border: 1px solid #ced4da; }

@media (max-width: 991.98px) {
  .collapsibleFormWrapper [data-toggle="collapse"] {
    padding: 0.5rem 0.75rem;
    position: relative;
    background-color: #e1ebf3;
    border-radius: 2px;
    cursor: pointer; }
    .collapsibleFormWrapper [data-toggle="collapse"]:after {
      background-image: url(sprite.png);
      background-position: 0px 0px;
      width: 8px;
      height: 10px;
      content: '';
      position: absolute;
      right: 1rem;
      top: 50%;
      margin-top: -5px;
      transform: rotate(90deg); }
    .collapsibleFormWrapper [data-toggle="collapse"].collapsed:after {
      transform: rotate(0); }
  .collapsibleFormWrapper .collapse {
    margin-bottom: 1.5rem; } }

@media (min-width: 992px) {
  .collapsibleFormWrapper [data-toggle="collapse"] {
    pointer-events: none; }
  .collapsibleFormWrapper .collapse {
    display: block; } }

.search.icon {
  color: #285492;
  position: absolute;
  margin-top: 2px;
  margin-left: 3px;
  width: 12px;
  height: 12px;
  border: solid 1px currentColor;
  border-radius: 100%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.search.icon:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 5px;
  height: 6px;
  width: 1px;
  background-color: currentColor; }

a.pdf,
a.doc,
a.link {
  display: inline-block;
  padding-left: 1.75rem;
  position: relative; }
  a.pdf:before,
  a.doc:before,
  a.link:before {
    content: '';
    display: inline-block;
    margin-right: 0.75rem;
    position: absolute;
    left: 0;
    vertical-align: middle; }

a.pdf:before {
  background-image: url(sprite.png);
  background-position: 0px -128px;
  width: 16px;
  height: 16px; }

a.doc:before {
  background-image: url(sprite.png);
  background-position: 0px -180px;
  width: 16px;
  height: 16px; }

a.link:before {
  background-image: url(sprite.png);
  background-position: 0px -154px;
  width: 16px;
  height: 16px; }

.form-control.quantity, .form-group input.quantity[type="text"],
.form-group input.quantity[type="email"],
.form-group input.quantity[type="password"],
.form-group textarea.quantity,
.form-group select.quantity {
  display: inline-block;
  width: 2.75em;
  padding-left: .5em;
  padding-right: .5em;
  text-align: center; }

.qtyBtn + .quantity {
  margin: 0 .2rem; }

.hotline {
  padding: 0.25rem 0;
  padding-left: 22px;
  position: relative;
  color: #7ac143;
  font-size: .92rem;
  font-weight: bold; }
  .hotline:before {
    background-image: url(sprite.png);
    background-position: 0px -82px;
    width: 16px;
    height: 11px;
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0.5rem; }

.breadcrumbs {
  margin: 1rem 0 1.5rem;
  font-size: .92rem; }

.breadcrumbs-list {
  list-style: none; }

.breadcrumb {
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap; }
  .breadcrumb a {
    margin-left: 8px;
    margin-right: -16px;
    padding: .5rem .66rem;
    position: relative;
    background-color: #F3F7FA;
    color: #212529; }
    .breadcrumb a:after, .breadcrumb a:before {
      content: '';
      position: absolute;
      left: -8px;
      top: 0;
      border: 1px solid #F3F7FA;
      border-width: 12px 0 13px 8px;
      border-left-color: transparent; }
    .breadcrumb a:after {
      left: auto;
      right: -8px;
      border: 1px solid transparent;
      border-width: 12px 0 13px 8px;
      border-left-color: #F3F7FA; }
    .breadcrumb a:hover {
      background-color: #e8f0f6;
      text-decoration: none; }
      .breadcrumb a:hover:after {
        border-left-color: #e8f0f6; }
      .breadcrumb a:hover:before {
        border-color: #e8f0f6;
        border-left-color: transparent; }
  .breadcrumb.last {
    display: none;
    margin-left: 16px; }
    @media (min-width: 768px) {
      .breadcrumb.last {
        display: inline-block; } }

.pagination {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem; }
  .pagination ul,
  .pagination li {
    display: inline-block; }
  .pagination a,
  .pagination .step,
  .pagination .currentStep {
    display: inline-block;
    min-width: 2.5rem;
    padding: .5rem;
    color: #285492;
    line-height: 1;
    text-align: center; }
  .pagination a,
  .pagination .currentStep {
    background-image: linear-gradient(to bottom, #fefeff 0%, #eceef2 100%);
    border: 1px solid #e9ecef; }
  .pagination .currentStep {
    font-weight: bold; }
  .pagination a:hover,
  .pagination .currentStep {
    border-color: #adb5bd;
    text-decoration: none; }
  .pagination .items-per-page > span {
    display: block; }
    @media (min-width: 992px) {
      .pagination .items-per-page > span {
        display: inline-block;
        margin-right: 1rem; } }
  .pagination .pages {
    margin-top: 1.5rem;
    text-align: right; }
    .pagination .pages a,
    .pagination .pages span {
      margin-right: .3rem; }
    @media (min-width: 992px) {
      .pagination .pages {
        margin-top: 0; } }
  .pagination .nextLink,
  .pagination .prevLink {
    position: relative;
    overflow: hidden;
    text-indent: -100px;
    vertical-align: top; }
    .pagination .nextLink:before,
    .pagination .prevLink:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -5.5px;
      margin-left: -5px; }
  .pagination .nextLink:before {
    background-image: url(sprite.png);
    background-position: 0px -61px;
    width: 10px;
    height: 11px; }
  .pagination .prevLink:before {
    background-image: url(sprite.png);
    background-position: 0px -40px;
    width: 10px;
    height: 11px; }

@media (max-width: 767.98px) {
  .modal-footer.flex {
    display: block; }
    .modal-footer.flex > .grow {
      margin-left: 0; }
    .modal-footer.flex a,
    .modal-footer.flex button {
      display: block;
      margin-top: 0.5rem;
      width: 100%; } }

@media (max-width: 991.98px) {
  .js-toggle-print {
    display: none; } }

.visible-print {
  display: none; }
  @media print {
    .visible-print {
      display: block; } }

.js .hidden-js,
.no-js .visible-js,
.no-js .hidden-nojs {
  display: none; }

/*
  #Home
 */
#teaser .slick-prev:before,
#teaser .slick-next:before {
  opacity: 0; }

#teaser .slick-prev:not(.slick-disabled):hover:before,
#teaser .slick-next:not(.slick-disabled):hover:before,
#teaser:hover .slick-prev:not(.slick-disabled):before,
#teaser:hover .slick-next:not(.slick-disabled):before {
  opacity: 1; }

.no-js #product-carousel-tabs {
  display: none; }

.carousel .slick-track {
  margin: 0; }

/*
  #Content
 */
.main {
  position: relative; }

.sidebar h2 {
  margin: 1rem 0;
  color: #7ac143;
  font-size: 2rem;
  font-weight: normal; }

.sidebar h3 {
  margin-top: 1.66667rem;
  font-size: 1.2rem;
  font-weight: bold; }

@media (min-width: 992px) {
  .menu-sidebar {
    line-height: 1; }
    .menu-sidebar .menu-item {
      display: block; }
    .menu-sidebar .menu-item--active {
      font-weight: bold; }
    .menu-sidebar .menu-link {
      padding: .5rem 0;
      color: #285492; }
    .menu-sidebar .active {
      padding-left: 2rem;
      position: relative; }
      .menu-sidebar .active > a {
        background: linear-gradient(to right, rgba(243, 244, 248, 0) 0%, #f3f4f8 54%, #f3f4f8 100%);
        font-weight: bold; }
      .menu-sidebar .active:before {
        background-image: url(sprite.png);
        background-position: 0px 0px;
        width: 8px;
        height: 10px;
        content: '';
        display: block;
        position: absolute;
        left: .5rem;
        top: 1.1rem;
        margin-top: -5px; } }

@media (min-width: 992px) {
  .menu-service > .menu-item > .menu-link {
    margin-top: 2.5rem;
    font: bold 1.2rem/1 Arial; }
    .menu-service > .menu-item > .menu-link:hover {
      text-decoration: none; } }

.meta-box {
  padding: 1rem;
  background-color: #F3F7FA; }

.account-box {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e9ecef; }

@media (max-width: 575.98px) {
  .account_order_filter select {
    margin-bottom: 1rem; } }

.orderList {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.orderItem {
  border-bottom: 2px solid #ced4da; }

.orderItem-head {
  display: flex;
  align-items: center;
  padding: 1rem 0.75rem;
  background-color: #F3F7FA;
  cursor: pointer; }
  .orderItem-head .btn {
    flex-shrink: 0; }

.orderItem-title {
  flex-grow: 1;
  margin: 0;
  font-size: 1.25rem; }

.orderItem-body {
  padding: 0.5rem; }

.orderItem-items {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15); }

.orderItem-shipment {
  padding: 1rem 0; }

.orderItem-bottom {
  margin-bottom: 1rem; }

.order-calc {
  display: flex; }

.order-calc-label {
  width: 11rem; }

.order-calc-item {
  flex-grow: 1;
  text-align: right; }

.cart-header {
  display: none;
  background-color: #F3F7FA; }
  @media (min-width: 768px) {
    .cart-header {
      display: flex; } }

.cart-header,
.cart-items {
  border: 1px solid #e9ecef; }

@media (min-width: 768px) {
  .cart-item > div {
    border-right: 1px solid #e9ecef; }
    .cart-item > div:last-child {
      border-right: 0; } }

.cart-items {
  margin: 0 0 1rem;
  padding: 0;
  list-style: none; }
  @media (min-width: 768px) {
    .cart-items {
      border-top: 0; } }

.cart-header > div,
.cart-item > div {
  padding: 0.5rem; }

.cart-item {
  border-bottom: 1px solid #e9ecef;
  text-align: right; }
  .cart-item:last-child {
    border-bottom: 0; }
  @media (min-width: 768px) {
    .cart-item {
      display: flex;
      flex-wrap: nowrap; }
      .cart-item .cart-item-qty,
      .cart-item .cart-item-price {
        padding-top: 2rem; } }
  .cart-item .net_price {
    font-size: .75rem; }

.cart-item-name a {
  font-weight: bold;
  text-decoration: underline; }

.cart-item-id {
  color: #92a8c5;
  font-size: .92rem; }

.cart-item-options {
  margin: 0;
  padding: 0;
  font-size: .92rem;
  list-style: none; }

.cart-item-img {
  text-align: center; }

.cart-item-img {
  width: 100px;
  float: left; }

.cart-item-info {
  margin-left: 100px;
  text-align: left; }

.cart-item-qty,
.cart-item-price_amount,
.cart-item-price_total {
  text-align: right; }

.cart-item-price_amount,
.cart-item-price_total {
  display: inline-block; }

@media (max-width: 767.98px) {
  .review-index .cart-item-qty:before {
    content: attr(data-label) ": "; }
  .cart-item-price_total {
    font-weight: bold; } }

@media (min-width: 768px) {
  .cart-item-img {
    width: 10%;
    float: none; }
  .cart-item-info {
    width: 40%;
    margin-left: 0; }
  .cart-item-qty {
    width: 13em; }
    .cart-item-qty .btn-remove {
      position: relative;
      top: -1px; }
  .cart-item-price_amount,
  .cart-item-price_total {
    width: 15%; } }

.cart-calc,
.cart-summary {
  margin-bottom: 1rem;
  font-size: .92rem; }
  .cart-calc > div,
  .cart-summary > div {
    display: flex;
    padding: 0.5rem 0; }
  @media (min-width: 768px) {
    .cart-calc,
    .cart-summary {
      padding-left: 40%; } }

.cart-summary-label {
  flex-grow: 1;
  text-align: right; }

.cart-summary-item {
  width: 8rem;
  flex-shrink: 0;
  text-align: right; }
  @media (min-width: 768px) {
    .cart-summary-item .cart-summary-item {
      width: 10rem; } }

.cart-calc-subtotal {
  font-size: 1.1rem; }

.cart-calc-subtotal-info {
  font-size: .92rem; }

.cart-calc-subtotal {
  border-bottom: 1px solid #e9ecef; }

.cart-summary-total {
  border-bottom: 3px double #e9ecef;
  font-size: 1.2rem; }

.cart-summary .cart-checkout {
  display: block;
  text-align: right; }

.cart-actions {
  position: relative; }

#cart_print {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 0.5rem; }

.cart-coupons {
  padding: 1rem;
  background-color: #F3F7FA;
  border: 1px solid #e9ecef; }
  .cart-coupons label {
    font-size: 1.2rem;
    font-weight: bold; }

.cart-sidebar {
  margin-top: 1rem; }
  @media (min-width: 992px) {
    .cart-sidebar {
      margin-top: 0; } }

.category-header h1,
.category-header h2 {
  margin: 0;
  color: #285492; }

.category-header h1 {
  font: normal 1.75rem/1 Verdana, Geneva, sans-serif; }
  @media (min-width: 768px) {
    .category-header h1 {
      font-size: 2rem; } }
  @media (min-width: 992px) {
    .category-header h1 {
      font-size: 2.4rem; } }

.category-header h2 {
  margin-bottom: 1rem;
  font: normal 1rem/1 Verdana, Geneva, sans-serif; }
  .category-header h2:before {
    background-image: url(sprite.png);
    background-position: 0px -437px;
    width: 21px;
    height: 21px;
    content: '';
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle; }

.category-header hgroup {
  padding: 1rem;
  background: #fafbfb url("https://static.medi-king.de/images/header/header_default.png") right top no-repeat;
  border: 1px solid #e9ecef; }
  .category-header hgroup.atmung {
    background-image: url("https://static.medi-king.de/images/header/header_atmung.png"); }
    .category-header hgroup.atmung h2:before {
      background-position: 0 -375px; }
  .category-header hgroup.herz-kreislauf {
    background-image: url("https://static.medi-king.de/images/header/header_herz-kreislauf.png"); }
    .category-header hgroup.herz-kreislauf h2:before {
      background-position: 0 -530px; }
  .category-header hgroup.diagnostik {
    background-image: url("https://static.medi-king.de/images/header/header_diagnostik.png"); }
    .category-header hgroup.diagnostik h2:before {
      background-position: 0 -468px; }
  .category-header hgroup.wundversorgung {
    background-image: url("https://static.medi-king.de/images/header/header_wundversorgung.png"); }
    .category-header hgroup.wundversorgung h2:before {
      background-position: 0 -871px; }
  .category-header hgroup.instrumente {
    background-image: url("https://static.medi-king.de/images/header/header_instrumente.png"); }
    .category-header hgroup.instrumente h2:before {
      background-position: 0 -654px; }
  .category-header hgroup.immobilisation {
    background-image: url("https://static.medi-king.de/images/header/header_immobilisation.png"); }
    .category-header hgroup.immobilisation h2:before {
      background-position: 0 -623px; }
  .category-header hgroup.taschen-koffer {
    background-image: url("https://static.medi-king.de/images/header/header_taschen-koffer.png"); }
    .category-header hgroup.taschen-koffer h2:before {
      background-position: 0 -809px; }
  .category-header hgroup.alltagstauglich {
    background-image: url("https://static.medi-king.de/images/header/header_alltagstauglich.png"); }
    .category-header hgroup.alltagstauglich h2:before {
      background-position: 0 -344px; }
  .category-header hgroup.hygiene {
    background-image: url("https://static.medi-king.de/images/header/header_hygiene.png"); }
    .category-header hgroup.hygiene h2:before {
      background-position: 0 -592px; }
  .category-header hgroup.technische-rettung {
    background-image: url("https://static.medi-king.de/images/header/header_technische-rettung.png"); }
    .category-header hgroup.technische-rettung h2:before {
      background-position: 0 -840px; }
  .category-header hgroup.ausbildung {
    background-image: url("https://static.medi-king.de/images/header/header_ausbildung.png"); }
    .category-header hgroup.ausbildung h2:before {
      background-position: 0 -406px; }
  .category-header hgroup.dokumentation {
    background-image: url("https://static.medi-king.de/images/header/header_dokumentation.png"); }
    .category-header hgroup.dokumentation h2:before {
      background-position: 0 -406px; }
  .category-header hgroup.erste-hilfe {
    background-image: url("https://static.medi-king.de/images/header/header_erste-hilfe.png"); }
    .category-header hgroup.erste-hilfe h2:before {
      background-position: 0 -499px; }
  .category-header hgroup.military {
    background-image: url("https://static.medi-king.de/images/header/header_military.png"); }
    .category-header hgroup.military h2:before {
      background-position: 0 -685px; }
  .category-header hgroup.humanitaere_hilfe {
    background-image: url("https://static.medi-king.de/images/header/header_humanitaere_hilfe.png"); }
    .category-header hgroup.humanitaere_hilfe h2:before {
      background-position: 0 -561px; }
  @media (max-width: 767.98px) {
    .category-header hgroup {
      background-image: none !important; } }

.category-header hgroup.aktionen {
  background-image: url("https://static.medi-king.de/images/header/header_sonderangebot.png"); }

.category-header hgroup.sale {
  background-image: url("https://static.medi-king.de/images/header/header_sale.png"); }

.category-header hgroup.new {
  background-image: url("https://static.medi-king.de/images/header/header_new.png"); }

.menu-collapsible .menu-category {
  border-bottom: 1px solid #e9ecef;
  line-height: 1; }
  .menu-collapsible .menu-category > li > .menu-link {
    display: block;
    padding: .75rem 2rem;
    padding-left: 2.5rem;
    background-image: linear-gradient(to bottom, white, #e9ecef); }
  .menu-collapsible .menu-category .active {
    position: relative;
    font-weight: bold; }
  .menu-collapsible .menu-category .mainCategory > .active:before {
    background-image: url(sprite.png);
    background-position: 0px 0px;
    width: 8px;
    height: 10px;
    content: '';
    display: block;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    margin-top: -5px; }
  .menu-collapsible .menu-category .menu-item {
    display: block; }
  .menu-collapsible .menu-category .menu-link {
    display: block;
    padding: 1rem;
    color: #285492; }
    .menu-collapsible .menu-category .menu-link:hover {
      background-color: #F3F7FA;
      text-decoration: none; }
  .menu-collapsible .menu-category .menu-link {
    border: 1px solid #e9ecef;
    border-bottom-width: 0; }
  .menu-collapsible .menu-category .menu-sublist .menu-link {
    padding-left: 2.5rem; }
  .menu-collapsible .menu-category .menu-sublist .menu-sublist .menu-link {
    padding-left: 4.5rem;
    border-top: 0; }

.productList .productListItem {
  flex: 0 0 50%;
  max-width: 50%; }
  @media (min-width: 768px) {
    .productList .productListItem {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 992px) {
    .productList .productListItem {
      flex: 0 0 25%;
      max-width: 25%; } }

.menu-multilevel {
  max-width: 25rem;
  position: relative;
  padding-top: 1rem; }
  .menu-multilevel .mml-trigger {
    position: relative;
    top: -0.5rem; }
  .menu-multilevel .mml-menu {
    width: 100%;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15); }
  .menu-multilevel .mml-item {
    background-color: #7ac143; }
    .menu-multilevel .mml-item .menu-link {
      display: block;
      padding: 1rem 1.66667rem;
      color: #fff; }
      .menu-multilevel .mml-item .menu-link:hover {
        background-color: rgba(255, 255, 255, 0.1); }

.checkout-headline {
  position: absolute;
  top: 1rem;
  right: 0;
  z-index: 1001;
  color: #8fbf00;
  font: 15px/100% Arial;
  font-weight: normal; }
  @media (min-width: 992px) {
    .checkout-headline {
      top: -2rem; } }

@media (max-width: 767.98px) {
  .checkout-headline-ext {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0; } }

.checkout-steps {
  margin: -1px -20px 2rem;
  padding: 0 10px;
  background-color: #F3F7FA;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  list-style: none; }
  .checkout-steps .step {
    padding: 1rem;
    counter-increment: checkout-steps;
    font: 1.25rem/100% Arial; }
    .checkout-steps .step.current {
      display: block; }
  @media (max-width: 991.98px) {
    .checkout-steps .step:not(.current) {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0; } }
  .checkout-steps span {
    position: relative; }
    .checkout-steps span:before {
      content: counter(checkout-steps);
      margin-right: 1rem;
      padding: .4rem .7rem;
      background-color: #7ac143;
      border-radius: 50%;
      box-shadow: 1px 1px 0 #fff, -1px -1px 0 rgba(0, 0, 0, 0.1);
      color: #fff;
      font: 1rem/100% Arial;
      font-weight: bold; }
  .checkout-steps .current {
    font-weight: bold; }
  @media (min-width: 992px) {
    .checkout-steps {
      display: flex;
      margin: 0 -24px 2rem;
      padding: 0; }
      .checkout-steps .step {
        flex-grow: 1;
        padding-left: 5rem;
        position: relative; }
        .checkout-steps .step:before, .checkout-steps .step:after {
          content: '';
          position: absolute;
          height: 0;
          width: 0;
          top: -1px;
          right: -21px;
          z-index: 10;
          border-color: transparent transparent transparent #fff;
          border-style: solid;
          border-width: 20px 0 20px 20px; }
        .checkout-steps .step:after {
          top: 0;
          right: -19px;
          z-index: 11;
          border-width: 19.5px 0 19.5px 19px;
          border-color: transparent transparent transparent #F3F7FA; }
        .checkout-steps .step:last-child:before, .checkout-steps .step:last-child:after {
          display: none; }
      .checkout-steps span:before {
        position: absolute;
        top: -3px;
        left: -32px;
        background-color: #ced4da; }
      .checkout-steps .current {
        background-color: #97c900;
        color: #fff;
        text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1); }
        .checkout-steps .current:after {
          border-color: transparent transparent transparent #97c900; }
        .checkout-steps .current span:before {
          box-shadow: none;
          background-color: rgba(0, 0, 0, 0.1); } }

.checkout-options {
  display: flex;
  justify-content: space-between; }

.paymentMethod-index .meta-box {
  margin-top: 1rem; }
  @media (min-width: 768px) {
    .paymentMethod-index .meta-box {
      margin-top: 0; } }

.checkout-form-wrapper,
.sidebar-checkout {
  padding: 1rem;
  background-color: #F3F7FA;
  border: 1px solid #e9ecef; }

.sidebar-checkout {
  margin-bottom: 1rem; }

.sidebar-checkout-box {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #dee2e6; }
  .sidebar-checkout-box:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0; }

@media (min-width: 768px) {
  .sidebar-checkout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .sidebar-checkout-box {
    flex-shrink: 0;
    width: 30%;
    width: calc(33% - 1rem);
    border-width: 0; } }

@media (min-width: 992px) {
  .sidebar-checkout {
    display: block; }
  .sidebar-checkout-box {
    width: auto;
    border-width: 1px; } }

.content {
  padding-top: 1rem; }

.content-service h2,
.content-service h3 {
  margin: 1.5em 0 .5em; }

.content-service h1 {
  margin-bottom: .75em; }
  @media (min-width: 768px) {
    .content-service h1 {
      font-size: 2.9em; } }

.content-service h2 {
  color: #7ac143;
  font: 1.5em; }

.content-service h3 {
  font: bold 1.1em/1.2 Verdana, Geneva, sans-serif; }

.content-service ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.content-service li {
  margin-bottom: 0.25rem; }

@media (max-width: 991.98px) {
  #page-index-page_downloads .content a {
    padding-top: .5em;
    padding-bottom: .5em; } }

.pdf-grid .pdf-item {
  flex: 0 0 50%;
  max-width: 50%; }
  @media (min-width: 768px) {
    .pdf-grid .pdf-item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  @media (min-width: 992px) {
    .pdf-grid .pdf-item {
      flex: 0 0 25%;
      max-width: 25%; } }

.productHeader h1 {
  color: #7ac143;
  font-size: 1.5rem;
  font-weight: normal; }
  @media (min-width: 992px) {
    .productHeader h1 {
      color: #285492; } }

.productImage {
  margin-bottom: 1rem;
  position: relative; }
  .productImage .productImage-detail {
    padding-top: 1px;
    border: 1px solid #ced4da;
    text-align: center; }
  .productImage .thumbnails-item {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ced4da; }
    .productImage .thumbnails-item.active {
      outline: 2px solid #ced4da; }
    .productImage .thumbnails-item:hover {
      border-color: #b1bbc4;
      outline-color: #b1bbc4; }

.productBody .tab-pane {
  overflow-x: hidden; }

@media (min-width: 768px) {
  .productImage {
    width: 30%;
    float: left; }
  .productHeader,
  .productBody {
    width: 68%;
    float: right; } }

.product-intro {
  display: flex;
  margin-bottom: 1rem; }
  .product-intro div:first-child {
    flex-grow: 1; }

.product-skus {
  margin-bottom: 1rem;
  width: 100%;
  font-size: .92rem; }

.sku-list-header {
  background-color: #F3F7FA;
  border: 1px solid #ced4da;
  border-bottom: 0; }
  @media (max-width: 991.98px) {
    .sku-list-header {
      display: none; } }

.sku-list-header th {
  font-weight: normal;
  line-height: 1; }

.sku-list-header th,
.sku-list td {
  padding: 0.833rem 0.5rem;
  border-right: 1px solid #ced4da;
  vertical-align: top; }
  .sku-list-header th:last-child,
  .sku-list td:last-child {
    border-right: 0; }

.skuItem {
  border-bottom: 1px solid #ced4da; }
  .skuItem:last-child {
    border-bottom: 0; }
  .skuItem .price-sale.discounted {
    color: #b21613; }

.skuItem-code {
  white-space: nowrap; }

.sku-list {
  margin: 0 0 1rem;
  padding: 0;
  border: 1px solid #ced4da;
  list-style: none; }

.skuItem-title {
  font-size: 1rem; }
  .skuItem-title a {
    display: block;
    padding-bottom: 1rem;
    text-decoration: underline; }

.skuItem-actions {
  text-align: center;
  white-space: nowrap; }

@media (max-width: 991.98px) {
  .skuItem {
    display: block;
    padding-bottom: 1rem; }
    .skuItem td {
      display: block;
      padding-bottom: 0;
      border-right: 0; }
  .skuItem-actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; }
  .skuItem-attribute:before,
  .skuItem-code:before {
    content: attr(data-label) ":";
    font-weight: bold; }
  .skuItem-title a {
    font-weight: bold; } }

@media (min-width: 992px) {
  .skuItem .price-sale {
    font-size: 1rem; }
  .skuItem-code {
    width: 8rem; }
  .skuItem-actions {
    width: 12rem; }
    .skuItem-actions .qtyBtn {
      display: none; } }

.btn-addToCart {
  padding: 0;
  position: relative;
  height: 1.75rem;
  width: 5rem;
  background-image: linear-gradient(to bottom, #fcfcfc, #efefef);
  border: 1px solid #91ad3f;
  border-radius: 2px;
  color: #91c100;
  cursor: pointer;
  font-size: .92rem;
  font-weight: bold;
  text-indent: -9999px;
  overflow: hidden; }
  .btn-addToCart span,
  .btn-addToCart span:before, .btn-addToCart:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0; }
  .btn-addToCart:after {
    content: 'OK';
    text-indent: 0;
    top: .1rem;
    right: .5rem;
    left: auto; }
  .btn-addToCart span:before {
    content: '';
    height: 20px;
    width: 100%;
    background-image: url("sprite.png");
    background-position: 0.5rem -315px; }
  .btn-addToCart span {
    background-color: #96bd23;
    background-image: linear-gradient(to bottom, #add536, #6e9300);
    width: 2.5rem;
    z-index: 2; }
  .btn-addToCart span,
  .btn-addToCart span:before {
    transition: all .3s ease-out; }
  .btn-addToCart:hover span {
    width: 5rem; }
  .btn-addToCart:hover span:before {
    background-position-x: 1.5rem; }
  .btn-addToCart.preorderable {
    color: #3b73aa;
    border-color: #4797e8; }
    .btn-addToCart.preorderable span {
      background-color: #7ABCFF;
      background-image: linear-gradient(to bottom, #7ABCFF, #4096EE); }
  .btn-addToCart.backorderable {
    color: #ccb943;
    border-color: #edd640; }
    .btn-addToCart.backorderable span {
      background-color: #FAE78B;
      background-image: linear-gradient(to bottom, #FAE78B, #F2DA36); }

.btn-addToWishList {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1rem;
  cursor: pointer;
  font-weight: normal; }
  .btn-addToWishList:before {
    background-image: url(sprite.png);
    background-position: 0px -232px;
    width: 16px;
    height: 16px;
    content: '';
    display: inline-block;
    margin-right: .2rem;
    vertical-align: middle; }

.price {
  text-align: right; }
  .new .price {
    color: #93c400; }
  .discounted .price {
    color: #b21613; }

[class^="price"] {
  white-space: nowrap; }

.price-sale {
  font-size: 1.17rem;
  font-weight: bold; }

.price-net {
  font-size: .833rem; }

.legend {
  margin: 0 0 2rem;
  padding: 0;
  list-style: none; }
  .legend li {
    margin-bottom: 0.25rem;
    padding-left: 3rem;
    position: relative; }
  .legend li:before {
    content: '';
    display: inline-block;
    height: 1.2rem;
    width: 2rem;
    position: absolute;
    left: 0;
    background-color: #495057;
    border-radius: 2px; }
  .legend .green:before {
    background-color: #96bd23;
    background-image: linear-gradient(to bottom, #add536, #6e9300); }
  .legend .yellow:before {
    background-color: #FAE78B;
    background-image: linear-gradient(to bottom, #FAE78B, #F2DA36); }
  .legend .blue:before {
    background-color: #7ABCFF;
    background-image: linear-gradient(to bottom, #7ABCFF, #4096EE); }

@media (min-width: 768px) {
  .modal .product-item {
    display: flex; } }

.modal .product-image {
  text-align: center; }

.modal .product-main {
  line-height: 1.6; }
  .modal .product-main .name {
    color: #7ac143;
    font: bold 1.25rem/1 Verdana, Geneva, sans-serif; }
  @media (min-width: 768px) {
    .modal .product-main {
      flex-grow: 1;
      margin-left: 3rem; } }

.modal .price {
  text-align-last: left; }

/**
 * EasyZoom core styles
 */
.easyzoom {
  position: relative;
  width: 100%;
  /* 'Shrink-wrap' the element */
  display: inline-block; }

.easyzoom img {
  vertical-align: bottom; }

.easyzoom.is-loading img {
  cursor: progress; }

.easyzoom.is-ready img {
  cursor: crosshair; }

.easyzoom.is-error img {
  cursor: not-allowed; }

.easyzoom-notice {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 150;
  width: 10em;
  margin: -1em 0 0 -5em;
  line-height: 2em;
  text-align: center;
  background: #FFF;
  box-shadow: 0 0 10px #888; }

.easyzoom-flyout {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1050;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  overflow: hidden;
  pointer-events: none; }
  .easyzoom-flyout:before {
    content: 'Zoom';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: rgba(73, 80, 87, 0.3);
    color: #fff;
    font-weight: bold;
    text-align: center; }
  .easyzoom-flyout img {
    max-width: none; }
  @media (min-width: 768px) {
    .easyzoom-flyout {
      left: 105%;
      height: 400px;
      min-height: 100%;
      max-height: 80vh;
      width: 230%; } }

.easyzoom-toggle {
  padding: .5rem; }

.wishList-header h1 {
  display: inline-block; }

.wishList-editMenu {
  display: inline-block; }

.wishList-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.wishListItem {
  margin-bottom: 1rem;
  padding: 0.5rem 0;
  border: 1px solid #e9ecef; }
  .wishListItem > div {
    padding: 0 0.5rem; }
  @media (min-width: 768px) {
    .wishListItem {
      display: flex; } }

.wishListItem-picture {
  flex-shrink: 0;
  text-align: center; }

.wishListItem-details {
  flex-grow: 1; }

.wishListItem-price,
.wishListItem-actions,
.remove-from-wishList {
  margin-top: 1rem;
  text-align: center; }

@media (min-width: 768px) {
  .wishListItem-price {
    text-align: right; } }

.wishListItem-price .price-base {
  font-size: .92rem; }

.wishList-context {
  float: right; }
  .wishList-context .dropdown-menu {
    min-width: 20em;
    max-width: 100%; }
  .wishList-context .btn {
    width: 100%;
    text-align-last: left; }
