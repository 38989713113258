.list-tick {
  padding-left: 0;

  list-style-type: none;

  li {
    padding: .3rem;
    padding-left: ($tick-width + 12px);
    position: relative;
  }

  li:before {
    @include sprite($tick);

    content: '';

    display: inline-block;
    position: absolute;
    left: 4px;

    vertical-align: middle;
  }
}

.list-divider {
  padding: 0;
  padding-right: 3rem;

  list-style: none;

  li {
    padding: 1rem .8rem;

    border-bottom: 1px solid $gray-200;
  }
}

.list-unstyled {
  @include list-unstyled;
}
