@mixin clearfix() {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin text-hide() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin transition($transition...) {
  @if $enable-transitions {
    transition: $transition;
  }
}

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    box-shadow: $shadow;
  }
}

$gradient-green: linear-gradient(to bottom, #add536, #6e9300);
@mixin gradient-green() {
  background-color: #96bd23;
  background-image: $gradient-green;
}
$gradient-yellow: linear-gradient(to bottom, #FAE78B, #F2DA36);
@mixin gradient-yellow() {
  background-color: #FAE78B;
  background-image: $gradient-yellow;
}
$gradient-blue: linear-gradient(to bottom, #7ABCFF, #4096EE);
@mixin gradient-blue() {
  background-color: #7ABCFF;
  background-image: $gradient-blue;
}

// based on _sprite.scss
// @mixin mk-sprite-image($sprite) {
//   $sprite-image: nth($sprite, 9);
//   background-image: url($sprite-image);
// }
// @mixin mk-sprite($sprite) {
//   @include mk-sprite-image($sprite);
//   @include sprite-position($sprite);
//   @include sprite-width($sprite);
//   @include sprite-height($sprite);
// }
