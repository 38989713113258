// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$arrow2-left-name: 'arrow2_left';
$arrow2-left-x: 0px;
$arrow2-left-y: 40px;
$arrow2-left-offset-x: 0px;
$arrow2-left-offset-y: -40px;
$arrow2-left-width: 10px;
$arrow2-left-height: 11px;
$arrow2-left-total-width: 85px;
$arrow2-left-total-height: 1240px;
$arrow2-left-image: 'sprite.png';
$arrow2-left: (0px, 40px, 0px, -40px, 10px, 11px, 85px, 1240px, 'sprite.png', 'arrow2_left', );
$arrow2-right-name: 'arrow2_right';
$arrow2-right-x: 0px;
$arrow2-right-y: 61px;
$arrow2-right-offset-x: 0px;
$arrow2-right-offset-y: -61px;
$arrow2-right-width: 10px;
$arrow2-right-height: 11px;
$arrow2-right-total-width: 85px;
$arrow2-right-total-height: 1240px;
$arrow2-right-image: 'sprite.png';
$arrow2-right: (0px, 61px, 0px, -61px, 10px, 11px, 85px, 1240px, 'sprite.png', 'arrow2_right', );
$arrow3-name: 'arrow3';
$arrow3-x: 0px;
$arrow3-y: 314px;
$arrow3-offset-x: 0px;
$arrow3-offset-y: -314px;
$arrow3-width: 20px;
$arrow3-height: 20px;
$arrow3-total-width: 85px;
$arrow3-total-height: 1240px;
$arrow3-image: 'sprite.png';
$arrow3: (0px, 314px, 0px, -314px, 20px, 20px, 85px, 1240px, 'sprite.png', 'arrow3', );
$arrow4-name: 'arrow4';
$arrow4-x: 0px;
$arrow4-y: 0px;
$arrow4-offset-x: 0px;
$arrow4-offset-y: 0px;
$arrow4-width: 8px;
$arrow4-height: 10px;
$arrow4-total-width: 85px;
$arrow4-total-height: 1240px;
$arrow4-image: 'sprite.png';
$arrow4: (0px, 0px, 0px, 0px, 8px, 10px, 85px, 1240px, 'sprite.png', 'arrow4', );
$arrow-left-name: 'arrow_left';
$arrow-left-x: 0px;
$arrow-left-y: 1030px;
$arrow-left-offset-x: 0px;
$arrow-left-offset-y: -1030px;
$arrow-left-width: 36px;
$arrow-left-height: 36px;
$arrow-left-total-width: 85px;
$arrow-left-total-height: 1240px;
$arrow-left-image: 'sprite.png';
$arrow-left: (0px, 1030px, 0px, -1030px, 36px, 36px, 85px, 1240px, 'sprite.png', 'arrow_left', );
$arrow-right-name: 'arrow_right';
$arrow-right-x: 0px;
$arrow-right-y: 1076px;
$arrow-right-offset-x: 0px;
$arrow-right-offset-y: -1076px;
$arrow-right-width: 36px;
$arrow-right-height: 36px;
$arrow-right-total-width: 85px;
$arrow-right-total-height: 1240px;
$arrow-right-image: 'sprite.png';
$arrow-right: (0px, 1076px, 0px, -1076px, 36px, 36px, 85px, 1240px, 'sprite.png', 'arrow_right', );
$facebook-big-name: 'facebook_big';
$facebook-big-x: 0px;
$facebook-big-y: 902px;
$facebook-big-offset-x: 0px;
$facebook-big-offset-y: -902px;
$facebook-big-width: 85px;
$facebook-big-height: 32px;
$facebook-big-total-width: 85px;
$facebook-big-total-height: 1240px;
$facebook-big-image: 'sprite.png';
$facebook-big: (0px, 902px, 0px, -902px, 85px, 32px, 85px, 1240px, 'sprite.png', 'facebook_big', );
$file-acrobat-name: 'file-acrobat';
$file-acrobat-x: 0px;
$file-acrobat-y: 128px;
$file-acrobat-offset-x: 0px;
$file-acrobat-offset-y: -128px;
$file-acrobat-width: 16px;
$file-acrobat-height: 16px;
$file-acrobat-total-width: 85px;
$file-acrobat-total-height: 1240px;
$file-acrobat-image: 'sprite.png';
$file-acrobat: (0px, 128px, 0px, -128px, 16px, 16px, 85px, 1240px, 'sprite.png', 'file-acrobat', );
$file-download-name: 'file-download';
$file-download-x: 0px;
$file-download-y: 154px;
$file-download-offset-x: 0px;
$file-download-offset-y: -154px;
$file-download-width: 16px;
$file-download-height: 16px;
$file-download-total-width: 85px;
$file-download-total-height: 1240px;
$file-download-image: 'sprite.png';
$file-download: (0px, 154px, 0px, -154px, 16px, 16px, 85px, 1240px, 'sprite.png', 'file-download', );
$file-word-name: 'file-word';
$file-word-x: 0px;
$file-word-y: 180px;
$file-word-offset-x: 0px;
$file-word-offset-y: -180px;
$file-word-width: 16px;
$file-word-height: 16px;
$file-word-total-width: 85px;
$file-word-total-height: 1240px;
$file-word-image: 'sprite.png';
$file-word: (0px, 180px, 0px, -180px, 16px, 16px, 85px, 1240px, 'sprite.png', 'file-word', );
$icon-alltagstauglich-name: 'icon_alltagstauglich';
$icon-alltagstauglich-x: 0px;
$icon-alltagstauglich-y: 344px;
$icon-alltagstauglich-offset-x: 0px;
$icon-alltagstauglich-offset-y: -344px;
$icon-alltagstauglich-width: 21px;
$icon-alltagstauglich-height: 21px;
$icon-alltagstauglich-total-width: 85px;
$icon-alltagstauglich-total-height: 1240px;
$icon-alltagstauglich-image: 'sprite.png';
$icon-alltagstauglich: (0px, 344px, 0px, -344px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_alltagstauglich', );
$icon-atmung-name: 'icon_atmung';
$icon-atmung-x: 0px;
$icon-atmung-y: 375px;
$icon-atmung-offset-x: 0px;
$icon-atmung-offset-y: -375px;
$icon-atmung-width: 21px;
$icon-atmung-height: 21px;
$icon-atmung-total-width: 85px;
$icon-atmung-total-height: 1240px;
$icon-atmung-image: 'sprite.png';
$icon-atmung: (0px, 375px, 0px, -375px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_atmung', );
$icon-ausbildung-name: 'icon_ausbildung';
$icon-ausbildung-x: 0px;
$icon-ausbildung-y: 406px;
$icon-ausbildung-offset-x: 0px;
$icon-ausbildung-offset-y: -406px;
$icon-ausbildung-width: 21px;
$icon-ausbildung-height: 21px;
$icon-ausbildung-total-width: 85px;
$icon-ausbildung-total-height: 1240px;
$icon-ausbildung-image: 'sprite.png';
$icon-ausbildung: (0px, 406px, 0px, -406px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_ausbildung', );
$icon-default-name: 'icon_default';
$icon-default-x: 0px;
$icon-default-y: 437px;
$icon-default-offset-x: 0px;
$icon-default-offset-y: -437px;
$icon-default-width: 21px;
$icon-default-height: 21px;
$icon-default-total-width: 85px;
$icon-default-total-height: 1240px;
$icon-default-image: 'sprite.png';
$icon-default: (0px, 437px, 0px, -437px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_default', );
$icon-diagnostik-name: 'icon_diagnostik';
$icon-diagnostik-x: 0px;
$icon-diagnostik-y: 468px;
$icon-diagnostik-offset-x: 0px;
$icon-diagnostik-offset-y: -468px;
$icon-diagnostik-width: 21px;
$icon-diagnostik-height: 21px;
$icon-diagnostik-total-width: 85px;
$icon-diagnostik-total-height: 1240px;
$icon-diagnostik-image: 'sprite.png';
$icon-diagnostik: (0px, 468px, 0px, -468px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_diagnostik', );
$icon-erste-hilfe-name: 'icon_erste-hilfe';
$icon-erste-hilfe-x: 0px;
$icon-erste-hilfe-y: 499px;
$icon-erste-hilfe-offset-x: 0px;
$icon-erste-hilfe-offset-y: -499px;
$icon-erste-hilfe-width: 21px;
$icon-erste-hilfe-height: 21px;
$icon-erste-hilfe-total-width: 85px;
$icon-erste-hilfe-total-height: 1240px;
$icon-erste-hilfe-image: 'sprite.png';
$icon-erste-hilfe: (0px, 499px, 0px, -499px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_erste-hilfe', );
$icon-ext-link-name: 'icon_ext_link';
$icon-ext-link-x: 0px;
$icon-ext-link-y: 206px;
$icon-ext-link-offset-x: 0px;
$icon-ext-link-offset-y: -206px;
$icon-ext-link-width: 16px;
$icon-ext-link-height: 16px;
$icon-ext-link-total-width: 85px;
$icon-ext-link-total-height: 1240px;
$icon-ext-link-image: 'sprite.png';
$icon-ext-link: (0px, 206px, 0px, -206px, 16px, 16px, 85px, 1240px, 'sprite.png', 'icon_ext_link', );
$icon-herz-kreislauf-name: 'icon_herz-kreislauf';
$icon-herz-kreislauf-x: 0px;
$icon-herz-kreislauf-y: 530px;
$icon-herz-kreislauf-offset-x: 0px;
$icon-herz-kreislauf-offset-y: -530px;
$icon-herz-kreislauf-width: 21px;
$icon-herz-kreislauf-height: 21px;
$icon-herz-kreislauf-total-width: 85px;
$icon-herz-kreislauf-total-height: 1240px;
$icon-herz-kreislauf-image: 'sprite.png';
$icon-herz-kreislauf: (0px, 530px, 0px, -530px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_herz-kreislauf', );
$icon-humanitaere-hilfe-name: 'icon_humanitaere_hilfe';
$icon-humanitaere-hilfe-x: 0px;
$icon-humanitaere-hilfe-y: 561px;
$icon-humanitaere-hilfe-offset-x: 0px;
$icon-humanitaere-hilfe-offset-y: -561px;
$icon-humanitaere-hilfe-width: 21px;
$icon-humanitaere-hilfe-height: 21px;
$icon-humanitaere-hilfe-total-width: 85px;
$icon-humanitaere-hilfe-total-height: 1240px;
$icon-humanitaere-hilfe-image: 'sprite.png';
$icon-humanitaere-hilfe: (0px, 561px, 0px, -561px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_humanitaere_hilfe', );
$icon-hygiene-name: 'icon_hygiene';
$icon-hygiene-x: 0px;
$icon-hygiene-y: 592px;
$icon-hygiene-offset-x: 0px;
$icon-hygiene-offset-y: -592px;
$icon-hygiene-width: 21px;
$icon-hygiene-height: 21px;
$icon-hygiene-total-width: 85px;
$icon-hygiene-total-height: 1240px;
$icon-hygiene-image: 'sprite.png';
$icon-hygiene: (0px, 592px, 0px, -592px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_hygiene', );
$icon-immobilisation-name: 'icon_immobilisation';
$icon-immobilisation-x: 0px;
$icon-immobilisation-y: 623px;
$icon-immobilisation-offset-x: 0px;
$icon-immobilisation-offset-y: -623px;
$icon-immobilisation-width: 21px;
$icon-immobilisation-height: 21px;
$icon-immobilisation-total-width: 85px;
$icon-immobilisation-total-height: 1240px;
$icon-immobilisation-image: 'sprite.png';
$icon-immobilisation: (0px, 623px, 0px, -623px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_immobilisation', );
$icon-instrumente-name: 'icon_instrumente';
$icon-instrumente-x: 0px;
$icon-instrumente-y: 654px;
$icon-instrumente-offset-x: 0px;
$icon-instrumente-offset-y: -654px;
$icon-instrumente-width: 21px;
$icon-instrumente-height: 21px;
$icon-instrumente-total-width: 85px;
$icon-instrumente-total-height: 1240px;
$icon-instrumente-image: 'sprite.png';
$icon-instrumente: (0px, 654px, 0px, -654px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_instrumente', );
$icon-military-name: 'icon_military';
$icon-military-x: 0px;
$icon-military-y: 685px;
$icon-military-offset-x: 0px;
$icon-military-offset-y: -685px;
$icon-military-width: 21px;
$icon-military-height: 21px;
$icon-military-total-width: 85px;
$icon-military-total-height: 1240px;
$icon-military-image: 'sprite.png';
$icon-military: (0px, 685px, 0px, -685px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_military', );
$icon-new-name: 'icon_new';
$icon-new-x: 0px;
$icon-new-y: 716px;
$icon-new-offset-x: 0px;
$icon-new-offset-y: -716px;
$icon-new-width: 21px;
$icon-new-height: 21px;
$icon-new-total-width: 85px;
$icon-new-total-height: 1240px;
$icon-new-image: 'sprite.png';
$icon-new: (0px, 716px, 0px, -716px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_new', );
$icon-news-name: 'icon_news';
$icon-news-x: 0px;
$icon-news-y: 1180px;
$icon-news-offset-x: 0px;
$icon-news-offset-y: -1180px;
$icon-news-width: 59px;
$icon-news-height: 60px;
$icon-news-total-width: 85px;
$icon-news-total-height: 1240px;
$icon-news-image: 'sprite.png';
$icon-news: (0px, 1180px, 0px, -1180px, 59px, 60px, 85px, 1240px, 'sprite.png', 'icon_news', );
$icon-sale-name: 'icon_sale';
$icon-sale-x: 0px;
$icon-sale-y: 747px;
$icon-sale-offset-x: 0px;
$icon-sale-offset-y: -747px;
$icon-sale-width: 21px;
$icon-sale-height: 21px;
$icon-sale-total-width: 85px;
$icon-sale-total-height: 1240px;
$icon-sale-image: 'sprite.png';
$icon-sale: (0px, 747px, 0px, -747px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_sale', );
$icon-sonderangebot-name: 'icon_sonderangebot';
$icon-sonderangebot-x: 0px;
$icon-sonderangebot-y: 778px;
$icon-sonderangebot-offset-x: 0px;
$icon-sonderangebot-offset-y: -778px;
$icon-sonderangebot-width: 21px;
$icon-sonderangebot-height: 21px;
$icon-sonderangebot-total-width: 85px;
$icon-sonderangebot-total-height: 1240px;
$icon-sonderangebot-image: 'sprite.png';
$icon-sonderangebot: (0px, 778px, 0px, -778px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_sonderangebot', );
$icon-taschen-koffer-name: 'icon_taschen-koffer';
$icon-taschen-koffer-x: 0px;
$icon-taschen-koffer-y: 809px;
$icon-taschen-koffer-offset-x: 0px;
$icon-taschen-koffer-offset-y: -809px;
$icon-taschen-koffer-width: 21px;
$icon-taschen-koffer-height: 21px;
$icon-taschen-koffer-total-width: 85px;
$icon-taschen-koffer-total-height: 1240px;
$icon-taschen-koffer-image: 'sprite.png';
$icon-taschen-koffer: (0px, 809px, 0px, -809px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_taschen-koffer', );
$icon-technische-rettung-name: 'icon_technische-rettung';
$icon-technische-rettung-x: 0px;
$icon-technische-rettung-y: 840px;
$icon-technische-rettung-offset-x: 0px;
$icon-technische-rettung-offset-y: -840px;
$icon-technische-rettung-width: 21px;
$icon-technische-rettung-height: 21px;
$icon-technische-rettung-total-width: 85px;
$icon-technische-rettung-total-height: 1240px;
$icon-technische-rettung-image: 'sprite.png';
$icon-technische-rettung: (0px, 840px, 0px, -840px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_technische-rettung', );
$icon-wundversorgung-name: 'icon_wundversorgung';
$icon-wundversorgung-x: 0px;
$icon-wundversorgung-y: 871px;
$icon-wundversorgung-offset-x: 0px;
$icon-wundversorgung-offset-y: -871px;
$icon-wundversorgung-width: 21px;
$icon-wundversorgung-height: 21px;
$icon-wundversorgung-total-width: 85px;
$icon-wundversorgung-total-height: 1240px;
$icon-wundversorgung-image: 'sprite.png';
$icon-wundversorgung: (0px, 871px, 0px, -871px, 21px, 21px, 85px, 1240px, 'sprite.png', 'icon_wundversorgung', );
$magnifier-name: 'magnifier';
$magnifier-x: 0px;
$magnifier-y: 103px;
$magnifier-offset-x: 0px;
$magnifier-offset-y: -103px;
$magnifier-width: 15px;
$magnifier-height: 15px;
$magnifier-total-width: 85px;
$magnifier-total-height: 1240px;
$magnifier-image: 'sprite.png';
$magnifier: (0px, 103px, 0px, -103px, 15px, 15px, 85px, 1240px, 'sprite.png', 'magnifier', );
$menu-transition-name: 'menu_transition';
$menu-transition-x: 0px;
$menu-transition-y: 944px;
$menu-transition-offset-x: 0px;
$menu-transition-offset-y: -944px;
$menu-transition-width: 82px;
$menu-transition-height: 33px;
$menu-transition-total-width: 85px;
$menu-transition-total-height: 1240px;
$menu-transition-image: 'sprite.png';
$menu-transition: (0px, 944px, 0px, -944px, 82px, 33px, 85px, 1240px, 'sprite.png', 'menu_transition', );
$paypal-name: 'paypal';
$paypal-x: 0px;
$paypal-y: 285px;
$paypal-offset-x: 0px;
$paypal-offset-y: -285px;
$paypal-width: 70px;
$paypal-height: 19px;
$paypal-total-width: 85px;
$paypal-total-height: 1240px;
$paypal-image: 'sprite.png';
$paypal: (0px, 285px, 0px, -285px, 70px, 19px, 85px, 1240px, 'sprite.png', 'paypal', );
$shopping-cart-name: 'shopping_cart';
$shopping-cart-x: 0px;
$shopping-cart-y: 1122px;
$shopping-cart-offset-x: 0px;
$shopping-cart-offset-y: -1122px;
$shopping-cart-width: 58px;
$shopping-cart-height: 48px;
$shopping-cart-total-width: 85px;
$shopping-cart-total-height: 1240px;
$shopping-cart-image: 'sprite.png';
$shopping-cart: (0px, 1122px, 0px, -1122px, 58px, 48px, 85px, 1240px, 'sprite.png', 'shopping_cart', );
$telephone-name: 'telephone';
$telephone-x: 0px;
$telephone-y: 82px;
$telephone-offset-x: 0px;
$telephone-offset-y: -82px;
$telephone-width: 16px;
$telephone-height: 11px;
$telephone-total-width: 85px;
$telephone-total-height: 1240px;
$telephone-image: 'sprite.png';
$telephone: (0px, 82px, 0px, -82px, 16px, 11px, 85px, 1240px, 'sprite.png', 'telephone', );
$tick-name: 'tick';
$tick-x: 0px;
$tick-y: 258px;
$tick-offset-x: 0px;
$tick-offset-y: -258px;
$tick-width: 25px;
$tick-height: 17px;
$tick-total-width: 85px;
$tick-total-height: 1240px;
$tick-image: 'sprite.png';
$tick: (0px, 258px, 0px, -258px, 25px, 17px, 85px, 1240px, 'sprite.png', 'tick', );
$trash-name: 'trash';
$trash-x: 0px;
$trash-y: 20px;
$trash-offset-x: 0px;
$trash-offset-y: -20px;
$trash-width: 9px;
$trash-height: 10px;
$trash-total-width: 85px;
$trash-total-height: 1240px;
$trash-image: 'sprite.png';
$trash: (0px, 20px, 0px, -20px, 9px, 10px, 85px, 1240px, 'sprite.png', 'trash', );
$twitter-big-name: 'twitter_big';
$twitter-big-x: 0px;
$twitter-big-y: 987px;
$twitter-big-offset-x: 0px;
$twitter-big-offset-y: -987px;
$twitter-big-width: 82px;
$twitter-big-height: 33px;
$twitter-big-total-width: 85px;
$twitter-big-total-height: 1240px;
$twitter-big-image: 'sprite.png';
$twitter-big: (0px, 987px, 0px, -987px, 82px, 33px, 85px, 1240px, 'sprite.png', 'twitter_big', );
$wishlist-name: 'wishlist';
$wishlist-x: 0px;
$wishlist-y: 232px;
$wishlist-offset-x: 0px;
$wishlist-offset-y: -232px;
$wishlist-width: 16px;
$wishlist-height: 16px;
$wishlist-total-width: 85px;
$wishlist-total-height: 1240px;
$wishlist-image: 'sprite.png';
$wishlist: (0px, 232px, 0px, -232px, 16px, 16px, 85px, 1240px, 'sprite.png', 'wishlist', );
$spritesheet-width: 85px;
$spritesheet-height: 1240px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites: ($arrow2-left, $arrow2-right, $arrow3, $arrow4, $arrow-left, $arrow-right, $facebook-big, $file-acrobat, $file-download, $file-word, $icon-alltagstauglich, $icon-atmung, $icon-ausbildung, $icon-default, $icon-diagnostik, $icon-erste-hilfe, $icon-ext-link, $icon-herz-kreislauf, $icon-humanitaere-hilfe, $icon-hygiene, $icon-immobilisation, $icon-instrumente, $icon-military, $icon-new, $icon-news, $icon-sale, $icon-sonderangebot, $icon-taschen-koffer, $icon-technische-rettung, $icon-wundversorgung, $magnifier, $menu-transition, $paypal, $shopping-cart, $telephone, $tick, $trash, $twitter-big, $wishlist, );
$spritesheet: (85px, 1240px, 'sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
