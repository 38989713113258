.wishList-header {
  h1 {
    display: inline-block;
  }
}

.wishList-editMenu {
  display: inline-block;
}

.wishList-list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.wishListItem {
  margin-bottom: $spacer;
  padding: ($spacer * .5) 0;

  border: 1px solid $gray-200;

  & > div {
    padding: 0 ($spacer * .5);
  }

  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.wishListItem-picture {
  flex-shrink: 0;

  text-align: center;
}

.wishListItem-details {
  flex-grow: 1;
  // padding: 0 $spacer;
}

.wishListItem-price,
.wishListItem-actions,
.remove-from-wishList {
  margin-top: $spacer;
  text-align: center;
}

@include media-breakpoint-up(md) {
  .wishListItem-price {
    text-align: right;
  }
}

.wishListItem-price .price-base {
    font-size: .92rem;
}

.wishList-context {
  float: right;

  .dropdown-menu {
    min-width: 20em;
    max-width: 100%;
  }

  .btn {
    width: 100%;

    text-align-last: left;
  }
}
