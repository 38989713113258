.newsticker {
  margin: $spacer 0;

  border: 1px solid $gray-200;
  background: $gray-200;
  background: linear-gradient(to bottom,  lighten($gray-200, 7%) 0%, lighten($gray-100, 3%) 100%); /* W3C */
}

.newsticker .list {
  line-height: 160%;
  padding: 1.5rem;

  @include media-breakpoint-up(lg) {
    height: 8rem;
    padding: 1.5rem 1.5rem 1.5rem 8rem;

    background: url(https://static.medi-king.de/images/icons/icon_news.png) no-repeat 20px 20px;
  }
}

.newsticker .list span {
  font-size: 11px;
  font-weight: bold;
  margin-right: 7px;
}

.newsticker .list img {
  float: right;
  padding: 0 0 20px 20px;
}
