.productHeader {
  h1 {
    color: $brand-secondary;
    font-size: 1.5rem;
    font-weight: normal;

    @include media-breakpoint-up(lg) {
      color: $brand-primary;
    }
  }
}

.productImage {
  margin-bottom: $spacer;
  position: relative;

  .productImage-detail {
    padding-top: 1px; // fixes issue with cut off top border on ipad

    border: 1px solid $gray-400;
    text-align: center;
  }

  .thumbnails-item {
    display: inline-block;
    margin-right: ($spacer * .5);
    margin-bottom: ($spacer * .5);

    border: 1px solid $gray-400;

    &.active {
      outline: 2px solid $gray-400;
    }

    &:hover {
      border-color: darken($gray-400, 10%);
      outline-color: darken($gray-400, 10%);
    }
  }
}

.productBody {
  .tab-pane {
    overflow-x: hidden;
  }
}

@include media-breakpoint-up(md) {
  .productImage {
    width: 30%;
    float: left;
  }

  .productHeader,
  .productBody {
    width: 68%;
    float: right;
  }
}

.product-intro {
  display: flex;
  margin-bottom: $spacer;

  div:first-child {
    flex-grow: 1;
  }
}

.product-skus {
  margin-bottom: $spacer;
  width: 100%;

  font-size: .92rem;
}

.sku-list-header {
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-bottom: 0;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.sku-list-header th {
  font-weight: normal;
  line-height: 1;
}

.sku-list-header th,
.sku-list td {
  padding: ($spacer * .833) ($spacer * .5);

  border-right: 1px solid $gray-400;
  vertical-align: top;

  &:last-child {
    border-right: 0;
  }
}

.skuItem {
  border-bottom: 1px solid $gray-400;

  &:last-child {
    border-bottom: 0;
  }

  .price-sale.discounted {
    color: #b21613;
  }
}

.skuItem-code {
  white-space: nowrap;
}

.sku-list {
  margin: 0 0 $spacer;
  padding: 0;

  border: 1px solid $gray-400;
  list-style: none;
}

.skuItem-title {
  font-size: 1rem;

  a {
    display: block;
    padding-bottom: $spacer;

    text-decoration: underline;
  }
}

.skuItem-actions {
  text-align: center;
  white-space: nowrap;
}

@include media-breakpoint-down(md) {
  .skuItem {
    display: block;
    padding-bottom: $spacer;

    td {
      display: block;
      padding-bottom: 0;

      border-right: 0;
    }
  }

  .skuItem-actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .skuItem-attribute,
  .skuItem-code {
    &:before {
      content: attr(data-label)':';

      font-weight: bold;
    }
  }

  .skuItem-title a {
    font-weight: bold;
  }
}

@include media-breakpoint-up(lg) {
  .skuItem {
    .price-sale {
      font-size: 1rem;
    }
  }

  .skuItem-code {
    width: 8rem;
  }

  .skuItem-actions {
    width: 12rem;

    .qtyBtn {
      display: none;
    }
  }
}

.btn-addToCart {
  padding: 0;
  position: relative;
  height: 1.75rem;
  width: 5rem;

  background-image: linear-gradient(to bottom, #fcfcfc, #efefef);
  border: 1px solid #91ad3f;
  border-radius: 2px;
  color: #91c100;
  cursor: pointer;
  font-size: .92rem;
  font-weight: bold;
  text-indent: -9999px;
  overflow: hidden;

  span,
  span:before,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  &:after {
    content: 'OK';

    text-indent: 0;
    top: .1rem;
    right: .5rem;
    left: auto;
  }

  span:before {
    content: '';

    height: $arrow3-height;
    width: 100%;

    background-image: url($spritesheet-image);
    background-position: .5rem ($arrow3-offset-y - 1px);
  }

  span {
    @include gradient-green;

    width: 2.5rem;
    z-index: 2;
  }

  span,
  span:before {
    transition: all .3s ease-out;
  }

  &:hover {
    span {
      width: 5rem;
    }

    span:before {
      background-position-x: 1.5rem;
    }
  }

  // Colors according to inventory status
  &.preorderable {
    color: #3b73aa;
    border-color: #4797e8;

    span {
      @include gradient-blue;
    }
  }
  &.backorderable {
    color: #ccb943;
    border-color: #edd640;

    span {
      @include gradient-yellow;
    }
  }
}

.btn-addToWishList {
  @include button-size($input-btn-padding-y-sm * .75, $input-btn-padding-x-sm * .75, $font-size-sm, $line-height-sm, $btn-border-radius-sm);

//   background: transparent;
  cursor: pointer;
//   font-size: .833rem;
  font-weight: normal;

  &:before {
    @include sprite($wishlist);

    content: '';

    display: inline-block;
    margin-right: .2rem;

    vertical-align: middle;
  }
}

.price {
  text-align: right;

  .new & {
    color: #93c400;
  }

  .discounted & {
    color: #b21613;
  }
}

[class^="price"] {
  white-space: nowrap;
}

.price-sale {
  font-size: 1.17rem;
  font-weight: bold;
}

.price-net {
  font-size: .833rem;
}

.legend {
  margin: 0 0 ($spacer * 2);
  padding: 0;

  list-style: none;

  li {
    margin-bottom: ($spacer * .25);
    padding-left: 3rem;
    position: relative;
  }

  li:before {
    content: '';

    display: inline-block;
    height: 1.2rem;
    width: 2rem;
    position: absolute;
    left: 0;

    background-color: $gray-700;
    border-radius: 2px;
  }

  .green:before {
    @include gradient-green;
  }
  .yellow:before {
    @include gradient-yellow;
  }
  .blue:before {
    @include gradient-blue;
  }
}

.modal {
  .product-item {
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  .product-image {
    text-align: center;
  }

  .product-main {
    line-height: 1.6;

    .name {
      color: $brand-secondary;
      font: bold 1.25rem/1 $font-family-base;
    }

    @include media-breakpoint-up(md) {
      flex-grow: 1;
      margin-left: ($spacer * 3);
    }
  }

  .price {
    text-align-last: left;
  }
}

// .dropdown--wishList .dropdown-menu {
//   min-width: 15em;
// }

/**
 * EasyZoom core styles
 */
.easyzoom {
  position: relative;
  width: 100%;

  /* 'Shrink-wrap' the element */
  display: inline-block;
}

.easyzoom img {
  vertical-align: bottom;
}

.easyzoom.is-loading img {
  cursor: progress;
}

.easyzoom.is-ready img {
  cursor: crosshair;
}

.easyzoom.is-error  img {
  cursor: not-allowed;
}

.easyzoom-notice {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 150;
  width: 10em;
  margin: -1em 0 0 -5em;
  line-height: 2em;
  text-align: center;
  background: #FFF;
  box-shadow: 0 0 10px #888;
}

// .easyzoom-flyout {
//   position:absolute;
//   z-index: 100;
//   overflow: hidden;
//   background: #FFF;
// }

// .zoomOverlay {
.easyzoom-flyout {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: $zindex-modal;

  background-color: #fff;
  // border: 1px solid $gray-400;
  border: 1px solid #fff;
  box-shadow: $dropdown-box-shadow;
  overflow: hidden;
  pointer-events: none;

  &:before {
    content: 'Zoom';

    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    background-color: rgba($gray-700, .3);
    color: #fff;
    font-weight: bold;
    text-align: center;
  }

  img {
    max-width: none;
  }

  @include media-breakpoint-up(md) {
    left: 105%;
    height: 400px;
    min-height: 100%;
    max-height: 80vh;
    width: 230%;
  }
}

.easyzoom-toggle {
  padding: .5rem;
}
