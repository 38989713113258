.menu-collapsible {
  .menu-sublist {
    display: none;

    animation: MenuCollapsibleOut 1.5s;
    overflow: hidden;

    // &.mc-animate-in {
    //   animation: MenuCollapsibleIn .4s;
    // }
    // &.mc-animate-out {
    //   animation: MenuCollapsibleOut .4s;
    // }
  }

  .active + .menu-sublist {
    display: block;

    animation: MenuCollapsibleIn 1.5s;
  }

  .menu-sublist .menu-sublist {
    display: block;
  }
}

@keyframes MenuCollapsibleIn {
  0% {
    display: none;
    max-height: 0;
  }

  1% {
    display: block;
  }

  100% {
    max-height: 1000px;
  }
}

// @keyframes MenuCollapsibleOut {
//   0% {
//     display: block;
//     max-height: 1000px;
//   }

//   99% {
//     max-height: 1px;
//   }

//   100% {
//     display: none;
//     max-height: 0;
//   }
// }
