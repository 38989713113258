#teaser {
  margin: 2rem 0 3rem;
  padding: 0;

  &:not(.slick-slider) {
    list-style: none;

    li {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }

  img {
    width: 100%;
  }
}

/* Slider */
.slick-slider
{
  position: relative;

  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
}

.slick-list
{
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus
{
  outline: none;
}
.slick-list.dragging
{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
  transform: translate3d(0, 0, 0);
}

.slick-track
{
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after
{
  display: table;

  content: '';
}
.slick-track:after
{
  clear: both;
}
.slick-loading .slick-track
{
  visibility: hidden;
}

.slider li,
.slick-slide
{
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
.slider li:first-child {
  display: block;
}
[dir='rtl'] .slick-slide
{
  float: right;
}
.slick-slide img
{
  display: block;
}
.slick-slide.slick-loading img
{
  display: none;
}
.slick-slide.dragging img
{
  pointer-events: none;
}
.slick-initialized .slick-slide
{
  display: block;
}
.slick-loading .slick-slide
{
  visibility: hidden;
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
/* Arrows */
.slick-prev,
.slick-next
{
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;
  z-index: 1;
  display: block;

  padding: 0;
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:not(.slick-disabled):hover:before,
.slick-next:not(.slick-disabled):hover:before,
.slick-slider:hover .slick-prev:not(.slick-disabled):before,
.slick-slider:hover .slick-next:not(.slick-disabled):before
{
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
  opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
  content: '';

  display: block;

  opacity: .5;
  color: white;
  transition: all .5s;
}

.slick-prev
{
  left: -18px;
}
.slick-prev:before
{
  @include sprite($arrow_left);
}

.slick-next
{
  right: -18px;
}
.slick-next:before
{
  @include sprite($arrow_right);
}

/* Dots */
.slick-dotted.slick-slider
{
  margin-bottom: 30px;
}

.slick-dots
{
  position: absolute;
  bottom: -20px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li
{
  position: relative;

  display: inline-block;

  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button
{
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 10px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
  opacity: 1;
}
.slick-dots li button:before
{
  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;

  content: '';
  text-align: center;

  opacity: .25;
  background-color: #3f6498;
  border-radius: 50%;
}
.slick-dots li.slick-active button:before
{
  opacity: .75;
  background-color: #3f6498;
}
