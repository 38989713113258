@import "../../../node_modules/bootstrap/scss/_functions.scss";

// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Color system
// Options
// Spacing
// Body
// Links
// Paragraphs
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code

//
// Color system
//

$white:  #fff;
$gray-100: #F3F7FA;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
);

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);

$brand-true:                #002e6e;
$brand-primary:             #285492;
$brand-secondary:           #7ac143;
// $brand-secondary:           #8cbe00;
$brand-success:             #5cb85c;
$brand-info:                #5bc0de;
$brand-warning:             #f0ad4e;
$brand-danger:              #d9534f;
// $brand-inverse:             $gray-900;

$mk-dark-blue:              #365e97;
$mk-light-blue:             #E6ECF4;

$theme-colors: (
  primary: $brand-primary,
  secondary: $brand-secondary,
  success: $brand-success,
  info: $brand-info,
  warning: $brand-warning,
  danger: $brand-danger,
  light: $gray-200,
  dark: $gray-800
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true;
$enable-shadows:            true;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       true;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
);

// Body
//
// Settings for the `<body>` element.

$body-bg:    #fff;
$body-color: $brand-primary;

// Links
//
// Style anchor elements.

$link-color:            theme-color("primary");
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: underline;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.


$container-max-widths: (
  sm: 98%,
  md: 98%,
  lg: 960px
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:          12;
$grid-gutter-width:     20px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         (4 / 3);
$line-height-sm:         1.5;

$border-width: 1px;
$border-color: $gray-200;

$border-radius:          .15rem;
$border-radius-lg:       .25rem;
$border-radius-sm:       .1rem;

$component-active-color: $white;
$component-active-bg:    theme-color("primary");

$caret-width:            .3em;

$transition-base:        all .2s ease-in-out;
$transition-fade:        opacity .15s linear;
$transition-collapse:    height .35s ease;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: Verdana, Geneva, sans-serif;
$font-family-serif:      Georgia, "Times New Roman", Times, serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem;
$font-size-sm:   .875rem;

$font-weight-light: 300;
$font-weight-normal: normal;
$font-weight-bold: bold;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1rem;
$h6-font-size: 1rem;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family:   Arial, Helvetica Neue, Helvetica, sans-serif;
$headings-font-weight:   normal;
$headings-line-height:   1.1;
$headings-color:         inherit;

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;

$lead-font-size:   1.25rem;
$lead-font-weight: 300;

$small-font-size: 80%;

$text-muted: $gray-400;

$abbr-border-color: $gray-400;

$blockquote-small-color:  $gray-400;
$blockquote-font-size:    ($font-size-base * 1.25);
$blockquote-border-color: $gray-200;
$blockquote-border-width: .25rem;

$hr-border-color: rgba(0,0,0,.1);
$hr-border-width: $border-width;

$mark-padding: .2em;

$dt-font-weight: bold;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba(0,0,0,.25);
$nested-kbd-font-weight: bold;

$list-inline-padding: 5px;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem;
$table-cell-padding-sm:         .3rem;

$table-bg:                      transparent;
$table-accent-bg:               $gray-100;
$table-hover-bg:                $gray-200;
$table-active-bg:               $table-hover-bg;

$table-border-width:            $border-width;
$table-border-color:            $gray-300;

$table-head-bg:                 $gray-200;
$table-head-color:              $gray-700;

$table-inverse-bg:              $gray-900;
$table-inverse-accent-bg:       rgba($white, .05);
$table-inverse-hover-bg:        rgba($white, .075);
$table-inverse-border-color:    lighten($gray-900, 7.5%);
$table-inverse-color:           $body-bg;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-y:       .5rem;
$input-btn-padding-x:       .75rem;
$input-btn-line-height:     1.25;

$input-btn-padding-y-sm:    .25rem;
$input-btn-padding-x-sm:    .5rem;
$input-btn-line-height-sm:  1.5;

$input-btn-padding-y-lg:    1rem;
$input-btn-padding-x-lg:    1.75rem;
$input-btn-line-height-lg:  1.5;

$btn-font-weight:                $font-weight-bold;
$btn-box-shadow:                 inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075);
$btn-focus-box-shadow:           0 0 0 3px rgba(theme-color("primary"), .25);
$btn-active-box-shadow:          inset 0 3px 5px rgba($black,.125);

$btn-link-disabled-color:        $gray-600;

$btn-block-spacing-y:            .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius;
$btn-border-radius-lg:           $border-radius-lg;
$btn-border-radius-sm:           $border-radius-sm;

$btn-transition:                 background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

// $btn-primary-color:              #fff;
// $btn-primary-bg:                 $brand-primary;
// $btn-primary-border:             darken($brand-primary, 5%);

// $btn-secondary-color:            #fff;
// $btn-secondary-bg:               $brand-secondary;
// $btn-secondary-border:           darken($brand-secondary, 5%);

// $btn-info-color:                 $gray-900;
// $btn-info-bg:                    $gray-400;
// $btn-info-border:                $btn-info-bg;

// $btn-success-color:              #fff;
// $btn-success-bg:                 $brand-success;
// $btn-success-border:             $btn-success-bg;

// $btn-warning-color:              #fff;
// $btn-warning-bg:                 $brand-warning;
// $btn-warning-border:             $btn-warning-bg;

// $btn-danger-color:               #fff;
// $btn-danger-bg:                  $brand-danger;
// $btn-danger-border:              $btn-danger-bg;

// Forms

$input-bg:                       $white;
$input-disabled-bg:              $gray-200;

$input-color:                    $gray-700;
$input-border-color:             rgba($black,.15);
$input-btn-border-width:         $border-width; // For form controls and buttons
$input-box-shadow:               inset 0 1px 1px rgba($black,.075);

$input-border-radius:            $border-radius;
$input-border-radius-lg:         $border-radius-lg;
$input-border-radius-sm:         $border-radius-sm;

$input-focus-bg:                 $input-bg;
$input-focus-border-color:       lighten(theme-color("primary"), 25%);
$input-focus-box-shadow:         $input-box-shadow, $btn-focus-box-shadow;
$input-focus-color:              $input-color;

$input-placeholder-color:        $gray-600;

$input-height-border:           $input-btn-border-width * 2;

$input-height-inner:            ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2);
$input-height:                  calc(#{$input-height-inner} + #{$input-height-border});

$input-height-inner-sm:         ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2);
$input-height-sm:               calc(#{$input-height-inner-sm} + #{$input-height-border});

$input-height-inner-lg:         ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2);
$input-height-lg:               calc(#{$input-height-inner-lg} + #{$input-height-border});

$input-transition:               border-color ease-in-out .15s, box-shadow ease-in-out .15s;

$form-text-margin-top:     .25rem;

$form-check-margin-bottom:  .5rem;
$form-check-input-gutter:   1.25rem;
$form-check-input-margin-y: .25rem;
$form-check-input-margin-x: .25rem;

$form-check-inline-margin-x: .75rem;

$form-group-margin-bottom:       1rem;

$input-group-addon-color:        $input-color;
$input-group-addon-bg:           $gray-200;
$input-group-addon-border-color: $input-border-color;
$input-group-btn-border-color:   $input-border-color;

$custom-control-gutter:   1.5rem;
$custom-control-spacer-y: .25rem;
$custom-control-spacer-x: 1rem;

$custom-control-indicator-size:       1rem;
$custom-control-indicator-bg:         #ddd;
$custom-control-indicator-bg-size:    50% 50%;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba($black,.1);

$custom-control-indicator-disabled-bg:       $gray-200;
$custom-control-description-disabled-color:  $gray-600;

$custom-control-indicator-checked-color:      $white;
$custom-control-indicator-checked-bg:         theme-color("primary");
$custom-control-indicator-checked-box-shadow: none;

$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px theme-color("primary");

$custom-control-indicator-active-color:      $white;
$custom-control-indicator-active-bg:         lighten(theme-color("primary"), 35%);
$custom-control-indicator-active-box-shadow: none;

$custom-checkbox-indicator-border-radius: $border-radius;
$custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");

$custom-checkbox-indicator-indeterminate-bg: theme-color("primary");
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23");
$custom-checkbox-indicator-indeterminate-box-shadow: none;

$custom-radio-indicator-border-radius: 50%;
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23");

$custom-select-padding-y:          .375rem;
$custom-select-padding-x:          .75rem ;
$custom-select-height:              $input-height ;
$custom-select-indicator-padding:   1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height:         $input-btn-line-height;
$custom-select-color:               $input-color;
$custom-select-disabled-color:      $gray-600;
$custom-select-bg:            $white;
$custom-select-disabled-bg:   $gray-200;
$custom-select-bg-size:       8px 10px; // In pixels because image dimensions
$custom-select-indicator-color: #333;
$custom-select-indicator:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23");
$custom-select-border-width:  $input-btn-border-width;
$custom-select-border-color:  $input-border-color;
$custom-select-border-radius: $border-radius;

$custom-select-focus-border-color: lighten(theme-color("primary"), 25%);
$custom-select-focus-box-shadow:   inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5);

$custom-select-font-size-sm:  75%;
$custom-select-height-sm: $input-height-sm;

$custom-file-height:           $input-height;
$custom-file-width:            14rem;
$custom-file-focus-box-shadow: 0 0 0 .075rem $white, 0 0 0 .2rem theme-color("primary");

$custom-file-padding-y:     $input-btn-padding-y;
$custom-file-padding-x:     $input-btn-padding-x;
$custom-file-line-height:   $input-btn-line-height;
$custom-file-color:         $input-color;
$custom-file-bg:            $input-bg;
$custom-file-border-width:  $input-btn-border-width;
$custom-file-border-color:  $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow:    $input-box-shadow;
$custom-file-button-color:  $custom-file-color;
$custom-file-button-bg:     $input-group-addon-bg;
// $custom-file-text: (
//   placeholder: (
//     en: "Choose file..."
//   ),
//   button-label: (
//     en: "Browse"
//   )
// );


// Form validation
$form-feedback-valid-color:   theme-color("success");
$form-feedback-invalid-color: theme-color("danger");

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:             10rem;
$dropdown-padding-y:             .5rem;
$dropdown-margin-top:            .125rem;
$dropdown-bg:                    #fff;
$dropdown-border-color:          rgba(0,0,0,.15);
$dropdown-border-width:          $border-width;
$dropdown-divider-bg:            #e5e5e5;
// $dropdown-box-shadow:            0 .5rem 1rem rgba(0,0,0,.175);
$dropdown-box-shadow:            0 .25rem .5rem rgba(0,0,0,.15);

$dropdown-link-color:            $gray-900;
$dropdown-link-hover-color:      darken($gray-900, 5%);
$dropdown-link-hover-bg:         #f5f5f5;

$dropdown-link-active-color:     $component-active-color;
$dropdown-link-active-bg:        $component-active-bg;

$dropdown-link-disabled-color:   $gray-400;

$dropdown-item-padding-x:        1.5rem;

$dropdown-header-color:          $gray-400;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown-backdrop:  990;
$zindex-navbar:             1000;
$zindex-dropdown:           1000;
$zindex-fixed:              1030;
$zindex-sticky:             1030;
$zindex-modal-backdrop:     1040;
$zindex-modal:              1050;
$zindex-popover:            1060;
$zindex-tooltip:            1070;


// Navbar

$navbar-border-radius:              $border-radius;
$navbar-padding-x:                  $spacer;
$navbar-padding-y:                  ($spacer / 2);

$navbar-brand-padding-y:            .25rem;

$navbar-divider-padding-y:          .425rem;

$navbar-toggler-padding-x:           .75rem;
$navbar-toggler-padding-y:           .5rem;
$navbar-toggler-font-size:           $font-size-lg;
$navbar-toggler-border-radius:       $btn-border-radius;
$navbar-toggler-padding-x:           .75rem;
$navbar-toggler-padding-y:           .5rem;

$navbar-dark-color:                 rgba(255,255,255,.5);
$navbar-dark-hover-color:           rgba(255,255,255,.75);
$navbar-dark-active-color:          rgba(255,255,255,1);
$navbar-dark-disabled-color:        rgba(255,255,255,.25);
$navbar-dark-toggler-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
$navbar-dark-toggler-border:        rgba(255,255,255,.1);

$navbar-light-color:                rgba(0,0,0,.5);
$navbar-light-hover-color:          rgba(0,0,0,.7);
$navbar-light-active-color:         rgba(0,0,0,.9);
$navbar-light-disabled-color:       rgba(0,0,0,.3);
$navbar-light-toggler-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
$navbar-light-toggler-border:       rgba(0,0,0,.1);

// Navs

$nav-item-margin:               .2rem;
$nav-item-inline-spacer:        1rem;
$nav-link-padding:              .5em 1em;
$nav-link-hover-bg:             $gray-200;
$nav-disabled-link-color:       $gray-400;
$nav-disabled-link-hover-color: $gray-400;
$nav-disabled-link-hover-bg:    transparent;

$nav-tabs-border-color:                       #ddd;
$nav-tabs-border-width:                       $border-width;
$nav-tabs-border-radius:                      $border-radius;
$nav-tabs-link-hover-border-color:            $gray-200;
$nav-tabs-active-link-hover-color:            $gray-700;
$nav-tabs-active-link-hover-bg:               $body-bg;
$nav-tabs-active-link-hover-border-color:     #ddd;
$nav-tabs-justified-link-border-color:        #ddd;
$nav-tabs-justified-active-link-border-color: $body-bg;

$nav-pills-border-radius:     $border-radius;
$nav-pills-active-link-color: $component-active-color;
$nav-pills-active-link-bg:    $component-active-bg;


// Pagination

$pagination-padding-x:                .75rem;
$pagination-padding-y:                .5rem;
$pagination-padding-x-sm:             .75rem;
$pagination-padding-y-sm:             .275rem;
$pagination-padding-x-lg:             1.5rem;
$pagination-padding-y-lg:             .75rem;


$pagination-color:                     $link-color;
$pagination-bg:                        #fff;
$pagination-border-width:              $border-width;
$pagination-border-color:              #ddd;

$pagination-hover-color:               $link-hover-color;
$pagination-hover-bg:                  $gray-200;
$pagination-hover-border:              #ddd;

$pagination-active-color:              #fff;
$pagination-active-bg:                 $brand-primary;
$pagination-active-border:             $brand-primary;

$pagination-disabled-color:            $gray-400;
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           #ddd;


// Jumbotron

$jumbotron-padding:              2rem;
$jumbotron-bg:                   $gray-200;


// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d;
$state-success-text:             #528253;
// $state-success-bg:               #dff0d8;
$state-success-bg:               #f0f4e3;
$state-success-border:           darken($state-success-bg, 10%);

$state-info-text:                #31708f;
// $state-info-bg:                  #d9edf7;
$state-info-bg:                  #f3f6fb;
$state-info-border:              darken($state-info-bg, 7%);

$state-warning-text:             #8a6d3b;
$state-warning-bg:               #fcf8e3;
$mark-bg:                        $state-warning-bg;
$state-warning-border:           darken($state-warning-bg, 5%);

$state-danger-text:              #a94442;
$state-danger-bg:                #f2dede;
$state-danger-border:            darken($state-danger-bg, 5%);


// Cards
$card-spacer-x:            1.25rem;
$card-spacer-y:            .75rem;
$card-border-width:        1px;
$card-border-radius:       $border-radius;
$card-border-color:        rgba(0,0,0,.125);
$card-border-radius-inner: calc(#{$card-border-radius} - #{$card-border-width});
$card-cap-bg:              #f5f5f5;
$card-bg:                  #fff;

$card-link-hover-color:    #fff;

$card-img-overlay-padding: 1.25rem;

$card-deck-margin:         .625rem;

$card-columns-sm-up-column-gap: 1.25rem;


// Tooltips

$tooltip-max-width:           200px;
$tooltip-color:               #fff;
$tooltip-bg:                  #000;
$tooltip-opacity:             .9;
$tooltip-padding-y:           3px;
$tooltip-padding-x:           8px;
$tooltip-margin:              3px;

$tooltip-arrow-width:         5px;
$tooltip-arrow-color:         $tooltip-bg;


// Popovers

$popover-inner-padding:               1px;
$popover-bg:                          #fff;
$popover-max-width:                   276px;
$popover-border-width:                $border-width;
$popover-border-color:                rgba(0,0,0,.2);
$popover-box-shadow:                  0 5px 10px rgba(0,0,0,.2);

$popover-title-bg:                    darken($popover-bg, 3%);
$popover-title-padding-x:             14px;
$popover-title-padding-y:             8px;

$popover-content-padding-x:           14px;
$popover-content-padding-y:           9px;

$popover-arrow-width:                 10px;
$popover-arrow-color:                 $popover-bg;

$popover-arrow-outer-width:           ($popover-arrow-width + 1px);
$popover-arrow-outer-color:           fade-in($popover-border-color, .05);


// Tags

$tag-default-bg:            $gray-400;
$tag-primary-bg:            $brand-primary;
$tag-success-bg:            $brand-success;
$tag-info-bg:               $brand-info;
$tag-warning-bg:            $brand-warning;
$tag-danger-bg:             $brand-danger;

$tag-color:                 #fff;
$tag-link-hover-color:      #fff;
$tag-font-size:             75%;
$tag-font-weight:           bold;
$tag-padding-x:             .4em;
$tag-padding-y:             .25em;

$tag-pill-padding-x:        .6em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$tag-pill-border-radius:    10rem;

// Modals

// Padding applied to the modal body
$modal-inner-padding:         1rem;

$modal-dialog-margin:         1rem;
$modal-dialog-sm-up-margin-y: 30px;

// $modal-title-padding:         1rem;
$modal-title-line-height:     $line-height-base;

$modal-content-bg:               #fff;
$modal-content-border-color:     rgba($gray-700, .9);
$modal-content-border-width:     1px;
$modal-content-xs-box-shadow:    0 3px 9px rgba(0,0,0,.5);
$modal-content-sm-up-box-shadow: 0 5px 15px rgba(0,0,0,.5);

$modal-backdrop-bg:           #000;
$modal-backdrop-opacity:      .3;
$modal-header-border-color:   #e5e5e5;
$modal-footer-border-color:   $modal-header-border-color;
$modal-header-border-width:   $modal-content-border-width;
$modal-footer-border-width:   $modal-header-border-width;
$modal-header-padding:        1rem;

$modal-lg:                    900px;
$modal-md:                    600px;
$modal-sm:                    300px;

$modal-transition:            transform .3s ease-out;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-x:             1.25rem;
$alert-padding-y:             .75rem;
$alert-margin-bottom:         $spacer;
$alert-border-radius:         $border-radius;
$alert-link-font-weight:      $font-weight-bold;
$alert-border-width:          $border-width;

$alert-success-bg:            $state-success-bg;
$alert-success-text:          $state-success-text;
$alert-success-border:        $state-success-border;

$alert-info-bg:               $state-info-bg;
$alert-info-text:             $state-info-text;
$alert-info-border:           $state-info-border;

$alert-warning-bg:            $state-warning-bg;
$alert-warning-text:          $state-warning-text;
$alert-warning-border:        $state-warning-border;

$alert-danger-bg:             $state-danger-bg;
$alert-danger-text:           $state-danger-text;
$alert-danger-border:         $state-danger-border;


// Progress bars

$progress-bg:                 #eee;
$progress-bar-color:          #0074d9;
$progress-border-radius:      $border-radius;
$progress-box-shadow:         inset 0 .1rem .1rem rgba(0,0,0,.1);

$progress-bar-bg:             $brand-primary;
$progress-bar-success-bg:     $brand-success;
$progress-bar-warning-bg:     $brand-warning;
$progress-bar-danger-bg:      $brand-danger;
$progress-bar-info-bg:        $brand-info;


// List group

$list-group-bg:                 #fff;
$list-group-border-color:       #ddd;
$list-group-border-width:       $border-width;
$list-group-border-radius:      $border-radius;

$list-group-hover-bg:           #f5f5f5;
$list-group-active-color:       $component-active-color;
$list-group-active-bg:          $component-active-bg;
$list-group-active-border:      $list-group-active-bg;
$list-group-active-text-color:  lighten($list-group-active-bg, 40%);

$list-group-disabled-color:      $gray-400;
$list-group-disabled-bg:         $gray-200;
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color:         #555;
$list-group-link-hover-color:   $list-group-link-color;
$list-group-link-heading-color: #333;

$list-group-item-padding-x:             1.25rem;
$list-group-item-padding-y:             .75rem;
$list-group-item-heading-margin-bottom: 5px;


// Image thumbnails

$thumbnail-padding:           .25rem;
$thumbnail-bg:                $body-bg;
$thumbnail-border-width:      $border-width;
$thumbnail-border-color:      #ddd;
$thumbnail-border-radius:     $border-radius;
$thumbnail-box-shadow:        0 1px 2px rgba(0,0,0,.075);


// Figures

$figure-caption-font-size: 90%;


// Breadcrumbs

$breadcrumb-padding-y:          .75rem;
$breadcrumb-padding-x:          1rem;
$breadcrumb-item-padding:       .5rem;

$breadcrumb-bg:                 $gray-200;
$breadcrumb-divider-color:      $gray-400;
$breadcrumb-active-color:       $gray-400;
$breadcrumb-divider:            "/";


// Media objects

$media-margin-top:            15px;
$media-heading-margin-bottom:  5px;
$media-alignment-padding-x:   10px;


// Carousel

$carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6);

$carousel-control-color:                      #fff;
$carousel-control-width:                      15%;
$carousel-control-sm-up-size:                 30px;
$carousel-control-opacity:                    .5;
$carousel-control-font-size:                  20px;

$carousel-indicators-width:                   60%;

$carousel-indicator-size:                     10px;
$carousel-indicator-active-size:              12px;
$carousel-indicator-active-bg:                #fff;
$carousel-indicator-border-color:             #fff;

$carousel-caption-width:                      70%;
$carousel-caption-sm-up-width:                60%;
$carousel-caption-color:                      #fff;

$carousel-icon-width:                         20px;


// Close

$close-font-size:             $font-size-base * 1.5;
$close-font-weight:           $font-weight-bold;
$close-color:                 $black;
$close-text-shadow:           0 1px 0 $white;


// Code

$code-font-size:              90%;
$code-padding-x:              .4rem;
$code-padding-y:              .2rem;
$code-color:                  #bd4147;
$code-bg:                     #f7f7f9;

$kbd-color:                   #fff;
$kbd-bg:                      #333;

$pre-bg:                      #f7f7f9;
$pre-color:                   $gray-900;
$pre-border-color:            #ccc;
$pre-scrollable-max-height:   340px;
