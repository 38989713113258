.nav-tabs {
  display: flex;
  padding: 0;
  margin-bottom: -1px;

  list-style: none;

  .nav-item {
    display: inline-block;
  }

  .nav-link {
    display: block;
    padding: 1rem 0;
    width: 12rem;
    position: relative;
    z-index: 1;

    // background-image: linear-gradient(to bottom, #eff1f4 0%,#fff 100%);
    background: $gray-100;
    border: 1px solid $gray-200;
    border-bottom: 1px solid $gray-400;
    text-align: center;

    &.active {
      background-color: #fff;
      border-color: $gray-400;
      border-bottom-color: #fff;
    }

    &.disabled {
      color: $gray-500;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.tab-pane {
  display: none;
  padding: $spacer;

  border: 1px solid $gray-400;

  &.active {
    display: block;
  }
}
