.content {
  padding-top: $spacer;
}

.content-service {
  h2,
  h3 {
    margin: 1.5em 0 .5em;
  }

  h1 {
    margin-bottom: .75em;

    @include media-breakpoint-up(md) {
      font-size: 2.9em;
    }
  }

  h2 {
    color: $brand-secondary;
    font: 1.5em;
  }

  h3 {
    font: bold 1.1em/1.2 $font-family-base;
  }

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  li {
    margin-bottom: ($spacer * .25);
  }
}

#page-index-page_downloads .content a {
  @include media-breakpoint-down(md) {
    padding-top: .5em;
    padding-bottom: .5em;
  }
}

.pdf-grid .pdf-item {
  @include make-col(6);
  // width: 49%;
  // width: calc(50% - .5rem);

  @include media-breakpoint-up(md) {
    @include make-col(4);
    // width: 32%;
    // width: calc(33% - .667rem);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(3);
    // width: 24%;
    // width: calc(25% - .75rem);
  }
}
