html {
  font-size: 12px;
}

body {
  background: #CECFD1;
  background: linear-gradient(to bottom, rgba(#000, .125), rgba(#000, 0) 400px),
              linear-gradient(to top, rgba(#000, .125), rgba(#000, 0) 400px),
              url('../images/bg.gif');
}

.wrapper {
  background-color: #fff;

  @include media-breakpoint-up(lg) {
    width: 996px;
    margin: auto;

    border: 4px solid rgba(#000, .15);
    border-width: 0 4px;
  }
}

img {
  max-width: 100%;
}

hr {
  margin-top: $spacer;
  margin-bottom: $spacer;
  border: 0;
  border-top: $hr-border-width solid $hr-border-color;
}

nav ul,
nav ol {
  margin: 0;
  padding: 0;

  list-style: none;
}

svg {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;

  fill: currentColor;
}

svg.icon {
  height: 1.5em;
  width: 1.5em;

  vertical-align: middle;
}

button .icon {
  margin-top: -.1em;
}

/*
  #Header / #Banner
 */
.brand {
  margin: 0 $spacer;
  width: 150px;
  max-width: 80%;

  @include media-breakpoint-up(lg) {
    height: 100px;
    width: 245px;
    margin: $spacer ($spacer * 2.5) $spacer 0;
    max-width: none;

    a {
      display: block;
      margin: 0;
      width: 100%;
      height: 100%;

      background-image: url('../images/medi-king.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    img {
        display: none;
    }
  }
}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ($spacer * .5) $spacer;
  margin: 0 ($spacer * -1) $spacer;
  position: relative;
  z-index: $zindex-navbar;

  background-color: #fff;
  border-bottom: 3px solid $brand-secondary;

  @include media-breakpoint-up(lg) {
    padding: 0;

    border-bottom: 0;
  }

  .store--b2c & {
    border-bottom-color: $brand-primary;
  }
}

.mp-trigger,
.search-trigger,
.cart-trigger {
  margin: 0;
  padding: .75rem;
  height: 3.5rem;
  width: 3.5rem;

  background: none;
  border: 0;
  color: $brand-true;
  cursor: pointer;
}

.mp-trigger {
  flex-shrink: 0;
}

.banner-mobile-utility {
  display: none;

  white-space: nowrap;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.banner-wrapper {
  flex-shrink: 0;
}

.banner-utility {
  display: none;
}

@include media-breakpoint-up(lg) {
  .banner-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;

    & > div {
      display: flex;
      position: relative;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
  }

  .banner-utility {
    display: block;
  }
}

#searchForm {
  // display: flex;
  margin: 1rem 0 0;
  padding: 1rem 1.25rem;
  position: relative;

  background:#f3f6fb;
  border:1px solid #e9eae6;

  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
}

.searchForm-inputWrapper {
  display: flex;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.search-field {
  margin: -2px 0 0 0;
  padding: .6rem;
  padding-left: ($magnifier-width + 18px);
  width: 100%;
  max-width: 24rem;
  flex-grow: 1;

  background: #fff url($spritesheet-image) no-repeat;
  background-position: ($magnifier-offset-x + 12px) ($magnifier-offset-y + 6px);
  border: 1px solid #cdcec6;
  box-shadow: inset 3px 3px 3px rgba(#000, .05);
  color: $gray-700;
  line-height: 1;

  @include media-breakpoint-up(lg) {
    width: 24rem;
  }
}

.search-submit {
  padding: .75rem;
  margin: 0 0 0 .5rem;

  background-color: $brand-secondary;
  background-image: linear-gradient(to bottom,  #9fd00c 0%,#8ebd00 100%);
  border: 1px solid #7ca500;
  border-radius: 2px;
  box-shadow: 0 0 1px rgba(0,0,0,0.2);
  color: #fff;
  cursor: pointer;
  font-size: .92rem;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;

  &:hover {
    background: #8ebd00;
    background: linear-gradient(to bottom,  #8ebd00 1%,#9fd00c 100%);
    text-shadow: 1px 1px 0 rgba(#000, .1);
  }
}

.search-results {
  position: absolute;
  top: 100%;
  left: 1.25rem;
  width: 100%;
  max-width: 24rem;
  z-index: 1;

  @include media-breakpoint-down(md) {
    left: 0;
    max-width: none;

    .ui-menu {
      top: 0!important;
      left: 0!important;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    border: 1px solid $gray-200;
    list-style: none;
  }

  .ui-menu-item {
    border-bottom: 1px solid $gray-200;

    &:last-child {
      border-bottom: 0;
      text-align: center;

      a {
        display: inline-block
      }
    }
  }

  .ui-menu-item-wrapper {
    display: flex;
    padding: ($spacer * .5);
    width: 100%;

    background-color: #fff;

    &:hover {
      text-decoration: none;
    }
  }

  .product-image {
    padding: 0 .5rem;
    width: 60px;
    flex-shrink: 0;
  }

  .product-meta {
    flex-grow: 1;
    margin-left: $spacer;
  }

  .name {
    font-weight: bold;
  }

  .category {
    color: $gray-700;
    font-size: .92rem;
  }
}

.smallCart {
  margin: 1rem 0 0;
  position: relative;
}

.smallCart-link {
  display: block;
  height: $shopping-cart-height;
  margin: $spacer 0;
  padding: 0 $spacer;
  padding-left: $shopping-cart-width + 50px;
  position: relative;

  background-image: url($spritesheet-image);
  background-position: $shopping-cart-offset-x $shopping-cart-offset-y;
  background-repeat: no-repeat;
  text-align: right;

  &:hover {
    text-decoration: none;
  }

  h2 {
    margin: 0;
    padding-top: 1rem;

    color: $brand-secondary;
    font-size: .92rem;
    font-weight: bold;
  }

  .count-items {
    padding: .25rem .3rem;
    position: absolute;
    top: 10px;
    left: $shopping-cart-width - 15px;

    background-color: $brand-secondary;
    color: #fff;
    font-size: .92rem;
    font-weight: bold;
    line-height: 1;
  }

  @include media-breakpoint-up(lg) {
    margin: 0;
  }
}

.cart-trigger {
  display: inline-block;
  position: relative;

  &[data-count]::before {
    content: attr(data-count);

    align-items: center;
    top: 2px;
    display: flex;
    height: 1.5rem;
    width: 1.5rem;
    justify-content: center;
    position: absolute;
    left: 0;

    background-color: $brand-secondary;
    border: 2px solid $white;
    border-radius: 9999px;
    color: $white;
    font-size: .8rem;
    line-height: 0;
    font-weight: 600;
  }
}

#overlay {
  display: none;
  position: fixed;
  z-index: ($zindex-navbar - 1);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  background-color: rgba(#000, .1);

  &.active {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .smallCart-link {
    display: inline-block;
  }
}

.ui-menu-item {
  a {
    img {
      border: 1px solid #fff;
    }

    &:hover,
    &:focus {
      background-color: $gray-100;
      text-decoration: none;

      img {
        border-color: $gray-200;
      }
    }
  }
}

.search-results .ui-menu {
  box-shadow: $dropdown-box-shadow;
}

.ui-helper-hidden-accessible {
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  width: 1px;

  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

@include media-breakpoint-down(md) {
  #searchForm,
  .smallCart {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0;

    &.active {
      display: block;
      z-index: $zindex-dropdown;
    }
  }

  #searchForm {
    box-shadow: 2px 2px 3px rgba(#000, .1);
  }
}

/*
  #Menus
 */
.menu-item {
  display: inline-block;
}

.menu-link {
  display: block;
}

nav.mobile {
  display: block;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  .menu-item {
    display: block;
  }

  a {
    color: inherit;
  }
}

nav.primary {
  display: none;
  margin-bottom: $spacer;
  justify-content: space-between;

  background-color: $brand-primary;
  background-image: linear-gradient(to bottom, $brand-primary, $brand-true);
  color: #fff;

  .menu-link {
    color: currentColor;
  }

  .menu-link--sale {
    font-weight: bold;
    color: #f33;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

nav.primary .menu-primary {
  display: flex;

  & > .menu-item > .menu-link {
    padding: .92rem 1.5rem;
    // width: 9rem;

    border-right: 1px solid #2e4b75;
    box-shadow: 1px 0 0 #6986b0;
    font-size: 13px;
    line-height: 1;
    text-align: center;
  }

  & > .menu-item:hover > a {
    background-color: $mk-dark-blue;
    // box-shadow: inset 0 -10px 10px $brand-true, inset 0 8px 10px #33527c, inset 10px 0 10px #33527c, inset -10px 0 10px #33527c;
  }

  .menu-item {
    position: relative;
  }

  .menu-link:hover,
  .menu-link:focus {
    text-decoration: none;
  }

  .menu-item:hover > .menu-submenu,
  .menu-submenu:hover {
    display: block;
  }

  .menu-submenu {
    display: none;
    padding: 0;
    position: absolute;
    left: 0;
    z-index: $zindex-dropdown;

    color: $body-color;
    box-shadow: $dropdown-box-shadow;

    // add triangle op top of sub menu
    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 23px;
      height: 0;
      width: 0;

      border: 1px solid transparent;
      border-width: 12px 13px 0 13px;
      border-top-color: $mk-dark-blue;
      z-index: 3;
    }

    &:after {
      border-width: 14px 14px 0 14px;
      border-top-color: #fff;
      z-index: 2;
      left: 22px;
      top: 0;
    }

    .menu-item {
      display: block;
    }

    .menu-item:hover > .menu-link,
    .menu-submenu .menu-link {
      background: #F4F6FB;
    }

    .menu-item:hover > .menu-link {
      font-weight: bold;
    }

    .menu-link {
      padding: .83rem 2rem .83rem 5rem;
      width: 20rem;

      background: $mk-light-blue;
      background: rgba($mk-light-blue, .95);
    }

    .menu-submenu {
      top: 0;
      left: 100%;

      // hide triangle for subsequent sub menus
      &:after,
      &:before {
        display: none;
      }
    }
  }
}

nav.primary .menu-primary + div {
  display: flex;
  height: ($menu-transition-height - 6px);
  flex-wrap: nowrap;
  align-self: center;
}

nav.primary .menu-secondary {
  .menu-item {
    padding: .3rem 0;
  }

  .menu-link {
    display: inline-block;
    margin-right: .5rem;
    padding-right: .5rem;

    font-size: 11px;
  }

  .menu-item:not(:last-child) .menu-link {
    border-right: 1px solid #345e99;
    box-shadow: 1px 0 0 #14335e;
  }
}

.menu-footer {
  padding: 0;
  margin-bottom: 0;

  .menu-item--category {
    margin-top: $spacer;
  }

  .menu-submenu {
    margin: $spacer 0 0;
    padding: 0;
  }

  .menu-item {
    display: block;
  }

  .menu-link {
    display: block;
    padding: .5rem 0;

    color: currentColor;

    @include media-breakpoint-up(md) {
      padding: .25rem 0;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
}

.menu-banner {
  @include alert-variant($gray-100, $gray-600, $gray-700);

  position: relative;
//   z-index: 1000;

  border-bottom: 1px solid #c4c5c7;
}

nav.bannerMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: $zindex-navbar+1;

  @include media-breakpoint-down(md) {
    .company {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    .hotline {
      display: none;
    }
  }
}

.bannerMenu__item {
  display: inline-block;
  position: relative;
}

.bannerMenu__link {
  display: block;
  padding: ($spacer * .5);

  border-top: 3px solid transparent;

  .store--b2b &--b2b,
  .store--b2c &--b2c,
  &:hover {
    border-top-color: currentColor;
    text-decoration: none;
  }

  &--b2c {
    opacity: .6;
  }
}

.bannerMenu__item__info {
    @include alert-variant($gray-100, $gray-400, $gray-700);

    padding: .5em .75em;
    position: absolute;
    top: 3.5em;
    width: 18em;
    z-index: $zindex-tooltip;
    margin-left: -50%;

    border-width: 1px;
    border-style: solid;
    box-shadow: 2px 2px 3px rgba(#000, .1);
    transition: opacity .3s;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    text-align: center;

    &:before,
    &:after {
        content: '';

        display: block;
        bottom: 100%;
        position: absolute;

        border: solid transparent;
    }

    &:after {
        left: 50%;
        margin-left: -8px;

        border-bottom-color: $gray-100;
        border-width: 8px;
    }

    &:before {
        left: 50%;
        margin-left: -9px;

        border-bottom-color: $gray-400;
        border-width: 9px;
    }

    .bannerMenu__link.hover + &,
    .bannerMenu__link:hover + & {
        visibility: visible;
        opacity: 1;
    }
}

.menu-company {
  padding: ($spacer * .25) 0;

  font-size: .92rem;

  .menu-link {
    display: inline-block;
    padding-left: 1.5rem;
  }
}

/*
  #Categories
 */
// Category icons
$categories:
('atmung' $icon_atmung-offset-y),
('herz-kreislauf' $icon_herz-kreislauf-offset-y),
('diagnostik' $icon_diagnostik-offset-y),
('wundversorgung' $icon_wundversorgung-offset-y),
('instrumente' $icon_instrumente-offset-y),
('immobilisation' $icon_immobilisation-offset-y),
('taschen-koffer' $icon_taschen-koffer-offset-y),
('alltagstauglich' $icon_alltagstauglich-offset-y),
('hygiene' $icon_hygiene-offset-y),
('technische-rettung' $icon_technische-rettung-offset-y),
('ausbildung' $icon_ausbildung-offset-y),
('dokumentation' $icon_ausbildung-offset-y),
('erste-hilfe' $icon_erste-hilfe-offset-y),
('military' $icon_military-offset-y),
('humanitaere_hilfe' $icon_humanitaere_hilfe-offset-y);

nav.primary .menu-submenu .menu-item {
  position: relative;

  &:before {
    content: '';
    display: none;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    margin-top: $icon_atmung-height * -.5;
    height: $icon_atmung-height;
    width: $icon_atmung-width;

    background-image: url($spritesheet-image);
    background-repeat: no-repeat;
    pointer-events: none;
  }

  @each $category, $offset-y in $categories {
    // add icons to navigation menu items
    &.nav_#{$category} {
      &:before {
        background-position: 0 $offset-y;
      }

      &:hover:before {
        display: block;
      }
    }
  }
}

/*
  #Footer
 */
.site-footer {
  margin-top: ($spacer * 3);

  color: $gray-900;

  a,
  a:hover {
    color: inherit;
  }

  ul a {
    display: block;
  }
}

.shop-information,
nav.footer {
  margin-top: ($spacer * 3);
  padding-top: $spacer;
}

.shop-information {
  background-color: #fff;
  background-image: linear-gradient(to bottom, #fcfdfd, #fff);
  border-top: 1px solid #eff0ee;

  [class^="col"]:nth-child(n+2) {
    position: relative;

    &:before {
      content: '';

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 1px;

      background-color: $gray-200;
      background: transparent linear-gradient(to bottom, rgba($gray-200, 0), $gray-200 20%, $gray-200 80%, rgba($gray-200, 0));
    }
  }

  .payment {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0;
      margin-bottom: ($spacer * 2/3);
      width: 11rem;

      border: 1px solid $gray-200;
      text-align: center;
    }

    li:nth-child(2n+1) {
      margin-right: ($spacer * 2/3);
    }

    a {
      display: block;
      padding: .75rem 0;

      color: $body-color;

      &:hover {
        text-decoration: none;
      }
    }

    .paypal {
      background:
        url($spritesheet-image)
        no-repeat
        60% ($paypal-offset-y + 10px);

      a {
        color: rgba(#fff, 0);
      }
    }
  }
}

.social-links {
  margin: 0;
  padding: 0;

  list-style: none;

  li {
    display: inline-block;
    margin-right: $spacer;
  }

  a {
    @include text-hide;

    display: block;
  }

  .facebook {
    @include sprite($facebook_big);
  }

  .twitter {
    @include sprite($twitter_big);
  }
}

// nav.footer,
.legal {
  padding: ($spacer * 1.5) 0;
  // background-color: #E6E7EA;
  color: $gray-700;
  font-size: .92rem;
}

.footer-headline {
  margin: $spacer 0;

  color: $brand-secondary;
  font-family: $font-family-sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
}

.checkout,
.error {
  .legal {
    border-top: 1px solid $gray-200;
    background-color: $gray-100;
  }
}

/*
  #Misc
 */
.spinner {
  width: 40px;
  height: 40px;
  margin: $spacer auto;
  background-color: $brand-primary;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

a.underline {
  text-decoration: underline;
}
a.highlight,
.btn-link.highlight {
  color: $brand-secondary;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

// TODO: remove and use bootstrap flex utility classes
.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.grow {
  flex-grow: 1;
}

.flex-start,
.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.ui-icon-trash {
  display: inline-block;

  @include sprite($trash);
}

.btn-remove {
  background-color: $gray-100;
  border: 1px solid $gray-400;
}

.collapsibleFormWrapper {
  @include media-breakpoint-down(md) {
    // styling for interactive collapse-toggles
    [data-toggle="collapse"] {
      padding: ($spacer * .5) ($spacer * .75);
      position: relative;

      background-color: darken($gray-100, 5%);
      border-radius: 2px;
      cursor: pointer;

      &:after {
        @include sprite($arrow4);

        content: '';

        position: absolute;
        right: $spacer;
        top: 50%;
        margin-top: ($arrow4-height * -.5);

        transform: rotate(90deg);
      }

      &.collapsed:after {
        transform: rotate(0);
      }
    }

    .collapse {
      margin-bottom: $spacer * 1.5;
    }
  }

  @include media-breakpoint-up(lg) {
    // disable collapse mechanic for large viewports
    [data-toggle="collapse"] {
      pointer-events: none;
    }

    .collapse {
      display: block;
    }
  }
}

.search.icon {
  color: $brand-primary;
  position: absolute;
  margin-top: 2px;
  margin-left: 3px;
  width: 12px;
  height: 12px;
  border: solid 1px currentColor;
  border-radius: 100%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.search.icon:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 5px;
  height: 6px;
  width: 1px;
  background-color: currentColor;
}

a.pdf,
a.doc,
a.link {
  display: inline-block;
  padding-left: 1.75rem;
  position: relative;

  &:before {
    content: '';

    display: inline-block;
    margin-right: ($spacer * .75);
    position: absolute;
    left: 0;

    vertical-align: middle;
  }
}

a.pdf:before {
  @include sprite($file-acrobat);
}
a.doc:before {
  @include sprite($file-word);
}
a.link:before {
  @include sprite($file-download);
}

.form-control.quantity {
  display: inline-block;
  width: 2.75em;
  padding-left: .5em;
  padding-right: .5em;

  text-align: center;
}

.qtyBtn + .quantity {
    margin: 0 .2rem;
}

.hotline {
  padding: ($spacer * .25) 0;
  padding-left: $telephone-width + 6px;
  position: relative;

  color: $brand-secondary;
  font-size: .92rem;
  font-weight: bold;

  &:before {
    @include sprite($telephone)
    content: '';

    display: inline-block;
    position: absolute;
    left: 0;
    top: ($spacer * .5);
  }
}

.breadcrumbs {
  margin: $spacer 0 ($spacer * 1.5);

  font-size: .92rem;
}

.breadcrumbs-list {
  list-style: none;
}

.breadcrumb {
  display: inline-block;
  margin-right: 8px;

  white-space: nowrap;

  a {
    margin-left: 8px;
    margin-right: -16px;
    padding: .5rem .66rem;
    position: relative;

    background-color: $gray-100;
    color: $gray-900;

    &:after,
    &:before {
      content: '';

      position: absolute;
      left: -8px;
      top: 0;

      border: 1px solid $gray-100;
      border-width: 12px 0 13px 8px;
      border-left-color: transparent;
    }

    &:after {
      left: auto;
      right: -8px;

      border: 1px solid transparent;
      border-width: 12px 0 13px 8px;
      border-left-color: $gray-100;
    }

    &:hover {
      background-color: darken($gray-100, 3%);
      text-decoration: none;

      &:after {
        border-left-color: darken($gray-100, 3%);
      }
      &:before {
        border-color: darken($gray-100, 3%);
        border-left-color: transparent;
      }
    }
  }

  &.last {
    display: none;
    margin-left: 16px;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer;

  ul,
  li {
    display: inline-block;
  }

  a,
  .step,
  .currentStep {
    display: inline-block;
    min-width: 2.5rem;
    padding: .5rem;

    color: $body-color;
    line-height: 1;
    text-align: center;
  }

  a,
  .currentStep {
    background-image: linear-gradient(to bottom, #fefeff 0%,#eceef2 100%);
    border: 1px solid $gray-200;
  }

  .currentStep {
    font-weight: bold;
  }

  a:hover,
  .currentStep {
    border-color: $gray-500;
    text-decoration: none;
  }

  .items-per-page {
    & > span {
      display: block;

      @include media-breakpoint-up(lg) {
        display: inline-block;
        margin-right: $spacer;
      }
    }
  }

  .pages {
    margin-top: 1.5rem; // compensate for items per page label

    text-align: right;

    a,
    span {
      margin-right: .3rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .nextLink,
  .prevLink {
    position: relative;

    overflow: hidden;
    text-indent: -100px;
    vertical-align: top;

    &:before {
        content: '';

        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: $arrow2_right-height * -.5;
        margin-left: $arrow2_right-width * -.5;
    }
  }

  .nextLink:before {
    @include sprite($arrow2_right);
  }
  .prevLink:before {
    @include sprite($arrow2_left);
  }
}

@include media-breakpoint-down(sm) {
  .modal-footer.flex {
    display: block;

    & > .grow {
      margin-left: 0;
    }

    a,
    button {
      display: block;
      margin-top: ($spacer * .5);
      width: 100%;
    }
  }
}

@include media-breakpoint-down(md) {
  .js-toggle-print {
    display: none;
  }
}

.visible-print {
  display: none;

  @media print {
    display: block;
  }
}

.js .hidden-js,
.no-js .visible-js,
.no-js .hidden-nojs {
  display: none;
}

/*
  #Home
 */
#teaser {
  .slick-prev:before,
  .slick-next:before
  {
    opacity: 0;
  }

  .slick-prev:not(.slick-disabled):hover:before,
  .slick-next:not(.slick-disabled):hover:before,
  &:hover .slick-prev:not(.slick-disabled):before,
  &:hover .slick-next:not(.slick-disabled):before
  {
    opacity: 1;
  }
}

#product-carousel-tabs {
  .no-js & {
    display: none;
  }
}

.carousel .slick-track {
  margin: 0;
}

/*
  #Content
 */
.main {
  position: relative;
}

.sidebar {
  h2 {
    margin: $spacer 0;

    color: $brand-secondary;
    font-size: 2rem;
    font-weight: normal;
  }

  h3 {
    margin-top: ($spacer * 5/3);

    font-size: 1.2rem;
    font-weight: bold;
  }
}

.menu-sidebar {
  @include media-breakpoint-up(lg) {
    line-height: 1;

    .menu-item {
      display: block;
    }

    .menu-item--active {
        font-weight: bold;
    }

    .menu-link {
      padding: .5rem 0;

      color: $body-color;
    }

    .active {
      padding-left: 2rem;
      position: relative;

      & > a {
        background: linear-gradient(to right,  rgba(#f3f4f8, 0) 0%, rgba(#f3f4f8, 1) 54%, rgba(#f3f4f8, 1) 100%);
        font-weight: bold;
    }

      &:before {
        @include sprite($arrow4);

        content: '';

        display: block;
        position: absolute;
        left: .5rem;
        top: 1.1rem;
        margin-top: ($arrow4-height / -2);
      }
    }
  }
}

.menu-service {
  @include media-breakpoint-up(lg) {
    & > .menu-item > .menu-link {
      margin-top: ($spacer * 2.5);

      font: bold 1.2rem/1 Arial;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
