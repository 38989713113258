$productList-gutter-widths: (
  xs: $spacer,
  sm: $spacer,
  md: $spacer,
  lg: $spacer,
  xl: $spacer
);

.productList {
  @include make-row();
  // justify-content: space-between;
  margin: 0 ($grid-gutter-width / -4);
  padding: 0;

  list-style: none;
}

.productListItem {
  @include make-col-ready();
  padding-left: ($grid-gutter-width / 4);
  padding-right: ($grid-gutter-width / 4);

  margin-bottom: 1rem;
  position: relative;
}

.productListItem-link {
  display: block;
  height: 100%;
  position: relative;

  background-color: $gray-100;
  border: 1px solid $gray-300;

  &:hover,
  &:focus {
    border-color: $gray-400;
    box-shadow: 0 0 5px rgba(#000, .1);
    text-decoration: none;
  }
}

.productListItem-picture {
  padding: ($spacer * .5);

  background-color: #fff;
  text-align: center;

  img {
    margin: auto;
  }
}

.productListItem-details {
  padding: .5rem;

  color: $body-color;
  font-size: .92rem;
}

.productListItem-title {
  height: 2.16rem;
  height: 3.24rem;

  font-size: .92rem;
  line-height: 1.2;
  overflow: hidden;
}

.productListItem-title,
.productListItem-price {
  margin-bottom: .5rem;
}

.productListItem-price,
.productListItem-bottom {
  text-align: right;
}

.productListItem-price {
  font-size: 1.17rem;
  font-weight: bold;
}

.productListItem-price-discounted {
  color: $gray-700;
}

.productListItem-price-bottom {
  font-size: .75rem;
}

.ribbon {
  padding: .5rem 0;
  position: absolute;
  top: .75rem;
  right: -5px;
  width: 3rem;

  background-color: $brand-secondary;
  color: #fff;
  line-height: 100%;
  text-align: center;

  &:before,
  &:after {
    content: '';

    position: absolute;
    // background-color: $brand-secondary;
  }

  &:before {
    top: 0;
    left: -2rem;
    border-style: solid;
    border-width: 0 2rem 2rem 0;
    border-color: transparent $brand-secondary transparent transparent;
  }

  .discounted & {
    background-color: #aa1212;

    &:before {
      border-color: transparent #aa1212 transparent transparent;
    }
  }

  &:after {
    top: -6px;
    right: 0;

    border-style: solid;
    border-width: 5px 0 0 5px;
    border-color: transparent transparent transparent rgba(0,0,0,0.4);
  }
}

// .discounted {
//   .ribbon {
//     background-color: #aa1212;

//     &:before {
//       border-color: transparent #aa1212 transparent transparent;
//     }
//   }
// }
