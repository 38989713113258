.cart-header {
  display: none;

  background-color: $gray-100;

  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.cart-header,
.cart-items {
  border: 1px solid $gray-200;
}

@include media-breakpoint-up(md) {
  .cart-item {
    & > div {
      border-right: 1px solid $gray-200;

      &:last-child {
        border-right: 0;
      }
    }
  }
}

.cart-items {
  margin: 0 0 $spacer;
  padding: 0;

  list-style: none;

  @include media-breakpoint-up(md) {
    border-top: 0;
  }
}

.cart-header,
.cart-item {
  & > div {
    padding: ($spacer * .5);
  }
}

.cart-item {
  border-bottom: 1px solid $gray-200;
  text-align: right;

  &:last-child {
    border-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: nowrap;

    .cart-item-qty,
    .cart-item-price {
      padding-top: ($spacer * 2);
    }
  }

  .net_price {
    font-size: .75rem;
  }
}

.cart-item-name a {
  font-weight: bold;
  text-decoration: underline;
}

.cart-item-id {
  color: #92a8c5;
  font-size: .92rem;
}

.cart-item-options {
  margin: 0;
  padding: 0;

  font-size: .92rem;
  list-style: none;
}

.cart-item-img {
  text-align: center;
}

.cart-item-img {
  width: 100px;
  float: left;
}

.cart-item-info {
  margin-left: 100px;

  text-align: left;
}

.cart-item-qty,
.cart-item-price_amount,
.cart-item-price_total {
  text-align: right;
}

.cart-item-price_amount,
.cart-item-price_total {
  display: inline-block;
}

@include media-breakpoint-down(sm) {
  .review-index .cart-item-qty {
    &:before {
      content: attr(data-label) ': ';
    }
  }

  .cart-item-price_total {
    font-weight: bold;
  }
}

@include media-breakpoint-up(md) {
  .cart-item-img {
    width: 10%;
    float: none;
  }

  .cart-item-info {
    width: 40%;
    margin-left: 0;
  }

  .cart-item-qty {
    width: 13em;

    .btn-remove {
      position: relative;
      top: -1px;
    }
  }

  .cart-item-price_amount,
  .cart-item-price_total {
    width: 15%;
  }
}

.cart-calc,
.cart-summary {
  margin-bottom: $spacer;

  font-size: .92rem;

  & > div {
    display: flex;
    padding: ($spacer * .5) 0;
  }

  @include media-breakpoint-up(md) {
    padding-left: 40%;
  }
}

.cart-summary-label {
  flex-grow: 1;
  text-align: right
}

.cart-summary-item {
  width: 8rem;
  flex-shrink: 0;

  text-align: right;

  @include media-breakpoint-up(md) {
    .cart-summary-item {
      width: 10rem;
    }
  }
}

.cart-calc-subtotal {
  font-size: 1.1rem;
}

.cart-calc-subtotal-info {
  font-size: .92rem;
}

.cart-calc-subtotal {
  border-bottom: 1px solid $gray-200;
}

.cart-summary-total {
  border-bottom: 3px double $gray-200;
  font-size: 1.2rem;
}

.cart-summary {
  .cart-checkout {
    display: block;

    text-align: right;
  }
}

.cart-actions {
  position: relative;
}

#cart_print {
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: ($spacer * .5);
}

.cart-coupons {
  padding: $spacer;

  background-color: $gray-100;
  border: 1px solid $gray-200;

  label {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.cart-sidebar {
  margin-top: $spacer;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }
}
