.meta-box {
  padding: $spacer;

  background-color: $gray-100;
}

.account-box {
  margin-bottom: $spacer;
  padding-bottom: $spacer;

  border-bottom: 1px solid $gray-200;
}

@include media-breakpoint-down(xs) {
  .account_order_filter select {
    margin-bottom: $spacer;
  }
}

.orderList {
  margin: 0;
  padding: 0;

  list-style-type: none;
}

.orderItem {
  border-bottom: 2px solid $gray-400;
}

.orderItem-head {
  display: flex;
  align-items: center;
  padding: $spacer ($spacer * .75);

  background-color: $gray-100;
  cursor: pointer;

  .btn {
    flex-shrink: 0;
  }
}

.orderItem-title {
  flex-grow: 1;
  margin: 0;

  font-size: 1.25rem;
}

.orderItem-body {
  padding: ($spacer * .5);
}

.orderItem-items {
  box-shadow: $dropdown-box-shadow;
}

.orderItem-shipment {
  padding: $spacer 0;
}

.orderItem-bottom {
  margin-bottom: $spacer;
}

.order-calc {
  display: flex;
}

.order-calc-label {
  width: 11rem;
}

.order-calc-item {
  flex-grow: 1;

  text-align: right;
}
