label {
  display: block;
}

fieldset {
  margin: 0 0 2em;
  padding: $spacer;
  position: relative;

  border: 1px solid $gray-200;
}

.form-alerts {
  ul {
  margin: 0;
  padding: 0;

  list-style: none;
  }

  li {
  border: $alert-border-width solid transparent;
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);

  margin-bottom: ($spacer * .5);
  padding: ($spacer * .5) $spacer;
  }
}

.form-control[readonly] {
  background-color: #f3f6f9;
}

.form-check-label {
  padding-left: 1.75rem;
}

.form-check-input {
  margin-left: -1.75rem;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group textarea,
.form-group select {
  @extend .form-control;
}

.form-group.form-group--inline {
  input {
    display: inline-block;
    width: auto;

    vertical-align: middle;
  }
}

.fieldLabel {
  cursor: pointer;

  .req &:after {
    content: ' *';
  }
}

.radio,
.checkBox {
  position: relative;

  label {
    display: block;
    width: auto;
    padding-left: 1.5em;
    *zoom: 1;
    *padding-left: 0;

    font-weight: normal;
    vertical-align: middle;
  }
  input {
    padding: 0;
    float: left;
    position: relative;
    top: .2em;

    border: 0;
    line-height: 1.5em;
    vertical-align: middle;
  }
  input[type="radio"] {
    top: .2em;
  }
}

/** webkit fix */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .radio input[type="radio"] {
    // small bug in chrome because 1px of the radio is hidden, don't know why
    left: 1px;
  }
}

/**
 * Error handling
 */
.formErrors {
  @extend .alert;
  @extend .alert-danger;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }
}

.fieldError {
  padding: ($spacer * .5) 0;
}

.error {
  color: $brand-danger;
}

.error .fieldLabel {
  font-weight: bold;
}

.error input {
  border-color: $brand-danger;
}
