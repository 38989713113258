html,
body,
.mp-push-container,
.mp-pusher,
.scroller {
  height: 100%;
  position: relative;
}

.mp-push-container {
  overflow: hidden;
}

.scroller {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.mp-trigger {
  display: inline-block;
  margin: .5rem;
  padding: 0;
  position: relative;
  height: 2.5rem;
  width: 3rem;

  border: 0;
  background: none;
  cursor: pointer;

  // &:before {
  //   content: '';

  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: .5rem;
  //   width: 3rem;
  //   background-color: $brand-primary;
  //   box-shadow: 0 .5rem #fff,
  //               0 1rem $brand-primary,
  //               0 1.5rem #fff,
  //               0 2rem $brand-primary;
  // }
}

$menuWidth: 20rem;
$divider-shadow-color: rgba(#000, .1);

.mp-pusher {
  left: 0;

  &.mp-pushed {
    cursor: pointer;
    transform: translate3d($menuWidth, 0, 0);
  }
}

.mp-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: $menuWidth;

  transform: translate3d(-100%, 0, 0);
}

.mp-level {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background-color: #fff;
  transform: translate3d(-100%, 0, 0);
  -webkit-overflow-scrolling: touch;
}

/* overlays for pusher and for level that gets covered */
.mp-pusher::after,
.mp-level::after,
.mp-level::before {
  content: '';

  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  width: 0;

  opacity: 0;
  cursor: pointer;
}

.mp-pusher::after,
.mp-level::after {
  background: rgba(#000, .3);
  transition: opacity .3s, width .1s .3s, height .1s .3s;
}

.mp-level::after {
  z-index: -1;
}

.mp-pusher.mp-pushed::after,
.mp-level.mp-level-overlay::after {
  height: 100%;
  width: 100%;

  opacity: 1;
  transition: opacity .3s;
}

.mp-level.mp-level-overlay {
  cursor: pointer;
}

.mp-level.mp-level-overlay.mp-level::before {
  height: 100%;
  width: 100%;

  background: transparent;
  opacity: 1;
}

.mp-pusher,
.mp-level {
  transition: all .4s;
}

/* overlap */
.mp-overlap .mp-level.mp-level-open {
  box-shadow: 1px 0 2px $divider-shadow-color;
  transform: translate3d(-40px, 0, 0);
}

/* First level */
.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
  box-shadow: none;
  transform: translate3d(0, 0, 0);
}

/* cover */
.mp-cover .mp-level.mp-level-open {
  transform: translate3d(0, 0, 0);
}

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
  transform: translate3d(-100%, 0, 0);
}

.mp-level-open:not(.mp-level-overlay){
  overflow-y: auto;
}

/* content style */
.mp-menu ul {
  margin: 0;
  padding: 0;

  list-style: none;
}

// .mp-menu h2 {
//   margin: 0;
//   padding: 1em;

//   color: rgba(#000, .4);
//   text-shadow: 0 0 1px rgba(#000, .1);
//   font-weight: 300;
//   font-size: 2em;
// }

// .mp-menu.mp-overlap h2::before {
//   position: absolute;
//   top: 0;
//   right: 0;
//   margin-right: 8px;

//   font-size: 75%;
//   line-height: 1.8;
//   opacity: 0;
//   transition: opacity .3s, transform .1s .3s;
//   transform: translateX(-100%);
// }

// .mp-menu.mp-cover h2 {
//   text-transform: uppercase;
//   font-weight: 700;
//   letter-spacing: 1px;
//   font-size: 1em;
// }

// .mp-overlap .mp-level.mp-level-overlay > h2::before {
//   opacity: 1;
//   transition: transform .3s, opacity .3s;
//   transform: translateX(0);
// }

.mp-menu ul li > a.menu-link {
  display: block;
  padding: 1.5rem 1.5rem;
  outline: none;
  box-shadow: inset 0 -1px $divider-shadow-color;
  text-shadow: 0 0 1px rgba(255,255,255,.1);
  font-size: 1.2rem;
  transition: background .3s, box-shadow .3s;
}

.mp-menu ul li::before {
  position: absolute;
  left: 10px;
  z-index: -1;
  color: rgba(#000, .2);
  line-height: 3.5;
}

.mp-level > ul > li:first-child > a {
  box-shadow: inset 0 -1px rgba(#000, .1), inset 0 2px rgba(#000, .1);
}

.mp-menu ul li a:hover,
.mp-level > ul > li:first-child > a:hover {
  background: rgba(#000, .15);
  box-shadow: inset 0 -1px rgba(#000, 0);
}

.mp-menu .mp-level.mp-level-overlay > ul > li > a,
.mp-level.mp-level-overlay > ul > li:first-child > a {
  box-shadow: inset 0 -1px rgba(#000, 0);
}

.mp-level > ul > li:first-child > a:hover,
.mp-level.mp-level-overlay > ul > li:first-child > a {
  box-shadow: inset 0 -1px rgba(#000, 0), inset 0 1px rgba(#000, 0);
} /* seems like Chrome 34..1847.131 needs the second shadow otherwise the transition breaks */

.mp-back {
  display: block;
  // padding: 1em;
  padding: 1.5rem 1.5rem;
  position: relative;

  background: rgba(#000, .1);
  box-shadow: inset 0 1px rgba(#000, .1);
  color: #fff;
  font-weight: 700;
  // font-size: .8em;
  letter-spacing: 1px;
  outline: none;
  text-transform: uppercase;
  transition: background .3s;
}

.mp-back::after {
  content: '<';

  position: absolute;
  right: 10px;

  font-size: 1.3em;
  color: rgba(#000, .2);
}

.mp-menu .mp-level.mp-level-overlay > .mp-back,
.mp-menu .mp-level.mp-level-overlay > .mp-back::after {
  background: transparent;
  box-shadow: none;
  color: transparent;
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
/* We'll show the first level only */
// .no-csstransforms3d .mp-pusher,
// .no-js .mp-pusher {
//   padding-left: $menuWidth;
// }

// .no-csstransforms3d .mp-menu .mp-level,
// .no-js .mp-menu .mp-level {
//   display: none;
// }

// .no-csstransforms3d .mp-menu > .mp-level,
// .no-js .mp-menu > .mp-level {
//   display: block;
// }
