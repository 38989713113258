.btn {
  cursor: default;
}

.btn-upperCase {
  font-size: .92rem;
  font-weight: bold;
  letter-spacing: .04rem;
  text-transform: uppercase;
}

.btn-secondary {
  color: $white;

  &:hover,
  &:focus {
    color: $white;
  }
}

.btn-light {
  color: $mk-dark-blue;
}

.btn-link {
    cursor: pointer;
}

// .btn-qty-decrement,
// .btn-qty-increment {
//   display: inline-block;
//   position: relative;
//   height: 1.8rem;
//   width: 1.8rem;

//   overflow: hidden;
//   text-indent: -10rem;

//   &:before {
//     content: '';

//     display: inline-block;
//     position: absolute;
//     left: 50%;
//     margin-left: -5px;

//     border: 5px solid currentColor;
//     border-color: currentColor transparent transparent transparent;
//     border-width: 7px 5px 0 5px;
//   }
//   // border-width: 0 5px 5px 5px;
//   // border-color: transparent transparent #D2D5E0 transparent;
// }

// .btn-qty-increment {
//   &:before {
//     border-color: transparent transparent currentColor transparent;
//     border-width: 0 5px 7px 5px;
//   }
// }
