.menu-multilevel {
  ul {
    transform-style: preserve-3d;
  }

  .menu-item,
  .menu-link {
    display: block;
    position: relative;
  }

  .mml-menu {
    position: absolute;
    z-index: 1;

    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    backface-visibility: hidden;

    &.mml-toggle {
      transition: all .3s ease;
    }

    &.mml-open {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }

    &.animate-out {
      animation: MenuAnimOut 0.3s ease-in-out;
    }

    &.animate-in {
      animation: MenuAnimIn 0.3s ease-in-out;
    }
  }

  .mml-back > .menu-link {
    background: rgba(0,0,0,0.1);
  }

  .mml-back:after,
  .menu-item > .menu-link:not(:only-child):after {
    content: '>';

    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -.75rem;

    color: inherit;
    speak: none;
  }

  .mml-back:after {
    content: '<';

    color: #fff;
  }

  /* Hide the inner submenus */
  .menu-item .menu-sublist {
    display: none;
  }

  /*
  When a submenu is openend, we will hide all li siblings.
  For that we give a class to the parent menu called "dl-subview".
  We also hide the submenu link.
  The opened submenu will get the class "dl-subviewopen".
  All this is done for any sub-level being entered.
  */
  .mml-menu.mml-subview .menu-item,
  .mml-menu.mml-subview .menu-item.mml-subviewopen > .menu-link,
  .mml-menu.mml-subview .menu-item.mml-subview > .menu-link {
    display: none;
  }

  .mml-menu.mml-subview .menu-item.mml-subview,
  .mml-menu.mml-subview .menu-item.mml-subview .mml-submenu,
  .mml-menu.mml-subview .menu-item.mml-subviewopen,
  .mml-menu.mml-subview .menu-item.mml-subviewopen > .mml-submenu,
  .mml-menu.mml-subview .menu-item.mml-subviewopen > .mml-submenu > .menu-item {
    display: block;
  }

  /* Dynamically added submenu outside of the menu context */
  & > .mml-submenu {
    position: absolute;
    width: 100%;
    // top: 50px;
    left: 0;
    margin: 0;
    z-index: 1;
  }

  & > .menu-sublist.animate-out {
    animation: SubMenuAnimOut 0.3s ease-in-out;
  }

  & > .menu-sublist.animate-in {
    animation: SubMenuAnimIn 0.3s ease-in-out;
  }
}

@keyframes MenuAnimOut {
  0% { }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes MenuAnimIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes SubMenuAnimOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes SubMenuAnimIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
